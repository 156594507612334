import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _689740ee = () => interopDefault(import('../pages/academy/index.vue' /* webpackChunkName: "pages/academy/index" */))
const _382e242a = () => interopDefault(import('../pages/academy-signup.vue' /* webpackChunkName: "pages/academy-signup" */))
const _6a88f799 = () => interopDefault(import('../pages/accept.vue' /* webpackChunkName: "pages/accept" */))
const _e2144914 = () => interopDefault(import('../pages/accept-contract.vue' /* webpackChunkName: "pages/accept-contract" */))
const _c29c656e = () => interopDefault(import('../pages/activate-secondary-mail.vue' /* webpackChunkName: "pages/activate-secondary-mail" */))
const _49193f4e = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _00f13574 = () => interopDefault(import('../pages/biva/index.vue' /* webpackChunkName: "pages/biva/index" */))
const _63eabc70 = () => interopDefault(import('../pages/bluetooth/index.vue' /* webpackChunkName: "pages/bluetooth/index" */))
const _f2c00622 = () => interopDefault(import('../pages/completeProfile.vue' /* webpackChunkName: "pages/completeProfile" */))
const _3589ce5a = () => interopDefault(import('../pages/config.vue' /* webpackChunkName: "pages/config" */))
const _9a303db4 = () => interopDefault(import('../pages/customer-signup.vue' /* webpackChunkName: "pages/customer-signup" */))
const _17a9eb51 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _74143cea = () => interopDefault(import('../pages/fitness/index.vue' /* webpackChunkName: "pages/fitness/index" */))
const _bd12bf98 = () => interopDefault(import('../pages/forgot.vue' /* webpackChunkName: "pages/forgot" */))
const _62bf6e44 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _9fdd85b0 = () => interopDefault(import('../pages/groups/index.vue' /* webpackChunkName: "pages/groups/index" */))
const _f289453e = () => interopDefault(import('../pages/HBO-signup.vue' /* webpackChunkName: "pages/HBO-signup" */))
const _4b65dda9 = () => interopDefault(import('../pages/keto/index.vue' /* webpackChunkName: "pages/keto/index" */))
const _3d5ce330 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _667e9d73 = () => interopDefault(import('../pages/regional/index.vue' /* webpackChunkName: "pages/regional/index" */))
const _7f274d64 = () => interopDefault(import('../pages/reset.vue' /* webpackChunkName: "pages/reset" */))
const _09a94930 = () => interopDefault(import('../pages/schedule.vue' /* webpackChunkName: "pages/schedule" */))
const _ed5b4f8e = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _711e5e77 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _d58ba8ae = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _f7fd753c = () => interopDefault(import('../pages/update-required.vue' /* webpackChunkName: "pages/update-required" */))
const _27a1caca = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _17618504 = () => interopDefault(import('../pages/academy/material/index.vue' /* webpackChunkName: "pages/academy/material/index" */))
const _85a4c522 = () => interopDefault(import('../pages/fitness/groups/index.vue' /* webpackChunkName: "pages/fitness/groups/index" */))
const _c61cdd22 = () => interopDefault(import('../pages/groups/deleted.vue' /* webpackChunkName: "pages/groups/deleted" */))
const _75c877d0 = () => interopDefault(import('../pages/keto/groups/index.vue' /* webpackChunkName: "pages/keto/groups/index" */))
const _27d840c6 = () => interopDefault(import('../pages/regional/groups/index.vue' /* webpackChunkName: "pages/regional/groups/index" */))
const _3e8656dd = () => interopDefault(import('../pages/settings/documents.vue' /* webpackChunkName: "pages/settings/documents" */))
const _0d736abd = () => interopDefault(import('../pages/settings/licenses.vue' /* webpackChunkName: "pages/settings/licenses" */))
const _57120814 = () => interopDefault(import('../pages/fitness/groups/deleted.vue' /* webpackChunkName: "pages/fitness/groups/deleted" */))
const _5c767bd2 = () => interopDefault(import('../pages/keto/groups/deleted.vue' /* webpackChunkName: "pages/keto/groups/deleted" */))
const _3f06258d = () => interopDefault(import('../pages/regional/groups/deleted.vue' /* webpackChunkName: "pages/regional/groups/deleted" */))
const _a112c394 = () => interopDefault(import('../pages/biva/regional/_sessionId.vue' /* webpackChunkName: "pages/biva/regional/_sessionId" */))
const _5951f010 = () => interopDefault(import('../pages/biva/sessions/_sessionId.vue' /* webpackChunkName: "pages/biva/sessions/_sessionId" */))
const _d619d29e = () => interopDefault(import('../pages/fitness/groups/_groupId/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/index" */))
const _03c15970 = () => interopDefault(import('../pages/keto/groups/_groupId/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/index" */))
const _5185453a = () => interopDefault(import('../pages/regional/groups/_groupId/index.vue' /* webpackChunkName: "pages/regional/groups/_groupId/index" */))
const _5e79a690 = () => interopDefault(import('../pages/fitness/groups/_groupId/deleted.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/deleted" */))
const _75e88892 = () => interopDefault(import('../pages/keto/groups/_groupId/deleted.vue' /* webpackChunkName: "pages/keto/groups/_groupId/deleted" */))
const _9cf845fe = () => interopDefault(import('../pages/regional/groups/_groupId/deleted.vue' /* webpackChunkName: "pages/regional/groups/_groupId/deleted" */))
const _10bcd0f8 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/index" */))
const _6e4bf67a = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/index" */))
const _1da7b0e6 = () => interopDefault(import('../pages/regional/groups/_groupId/patients/_patientId/index.vue' /* webpackChunkName: "pages/regional/groups/_groupId/patients/_patientId/index" */))
const _554ca73a = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/somatotype.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/somatotype" */))
const _72deae84 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/somatotype.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/somatotype" */))
const _7c3be3f0 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/deleted.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/deleted" */))
const _358ea22f = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/deleted.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/deleted" */))
const _009e5af9 = () => interopDefault(import('../pages/regional/groups/_groupId/patients/_patientId/exams/deleted.vue' /* webpackChunkName: "pages/regional/groups/_groupId/patients/_patientId/exams/deleted" */))
const _4ec84528 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/index" */))
const _4fdbd738 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/cardio/_cardioId/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/cardio/_cardioId/index" */))
const _ed3af368 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/circumference/_circumferenceId/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/circumference/_circumferenceId/index" */))
const _96b21dda = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/index" */))
const _28a8582c = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/fitness/_fitnessId/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/fitness/_fitnessId/index" */))
const _455d53b3 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/skinfolds/_skinfoldId/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/skinfolds/_skinfoldId/index" */))
const _6c599233 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/trainings/_trainingId/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/trainings/_trainingId/index" */))
const _1c8502cd = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/index" */))
const _da5ca04e = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/cardio/_cardioId/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/cardio/_cardioId/index" */))
const _bf879da6 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/circumference/_circumferenceId/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/circumference/_circumferenceId/index" */))
const _e5c877dc = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/index" */))
const _f4eae6e6 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/fitness/_fitnessId/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/fitness/_fitnessId/index" */))
const _3d0ab7b2 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/skinfolds/_skinfoldId/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/skinfolds/_skinfoldId/index" */))
const _6406f632 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/trainings/_trainingId/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/trainings/_trainingId/index" */))
const _6da78548 = () => interopDefault(import('../pages/regional/groups/_groupId/patients/_patientId/exams/_examId/index.vue' /* webpackChunkName: "pages/regional/groups/_groupId/patients/_patientId/exams/_examId/index" */))
const _64a2b614 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/bia.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/bia" */))
const _9f681470 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/fitnessBia.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/fitnessBia" */))
const _cd5bef6c = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/fluidRetention.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/fluidRetention" */))
const _54ac4fe5 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/qualitative.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/qualitative" */))
const _13f5f4ad = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/quantitative.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/quantitative" */))
const _2eb821c8 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/reports/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/reports/index" */))
const _07ad4ed8 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/cardio/_cardioId/hr.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/cardio/_cardioId/hr" */))
const _72333680 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/circumference/_circumferenceId/radar.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/circumference/_circumferenceId/radar" */))
const _27dc877b = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/bia.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/bia" */))
const _c8670974 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/circumferenceRadar.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/circumferenceRadar" */))
const _2c2e1281 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/fitnessBia.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/fitnessBia" */))
const _2e15befa = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/fluidRetention.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/fluidRetention" */))
const _921053dc = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/functional.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/functional" */))
const _14310499 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/hr.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/hr" */))
const _22bdccb5 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/load.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/load" */))
const _550dca4c = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/qualitative.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/qualitative" */))
const _1fc3c726 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/quantitative.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/quantitative" */))
const _56906bc3 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/reports/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/reports/index" */))
const _26619ae9 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/skinfoldRadar.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/skinfoldRadar" */))
const _46c8f434 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/somatotype.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/somatotype" */))
const _bf977132 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/zscore.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/zscore" */))
const _ed3012ce = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/fitness/_fitnessId/functional.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/fitness/_fitnessId/functional" */))
const _2e2e03e7 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/skinfolds/_skinfoldId/radar.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/skinfolds/_skinfoldId/radar" */))
const _07a28795 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/trainings/_trainingId/load.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/trainings/_trainingId/load" */))
const _54b08096 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/bia.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/bia" */))
const _3ed80507 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/fitnessBia.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/fitnessBia" */))
const _4e933fee = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/fluidRetention.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/fluidRetention" */))
const _d0bbaef4 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/qualitative.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/qualitative" */))
const _2fbb302c = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/quantitative.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/quantitative" */))
const _4586237d = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/reports/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/reports/index" */))
const _56c3a8da = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/cardio/_cardioId/hr.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/cardio/_cardioId/hr" */))
const _ede63d3e = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/circumference/_circumferenceId/radar.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/circumference/_circumferenceId/radar" */))
const _61180e3a = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/bia.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/bia" */))
const _0b28bea7 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/circumferenceRadar.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/circumferenceRadar" */))
const _a5bcb9ea = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/estimates.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/estimates" */))
const _eddf723c = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/fitnessBia.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/fitnessBia" */))
const _32178738 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/fluidRetention.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/fluidRetention" */))
const _13da0a73 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/functional.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/functional" */))
const _581a29fa = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/hr.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/hr" */))
const _496eb9bc = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/ketone.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/ketone" */))
const _10f31dd6 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/load.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/load" */))
const _d71bbbea = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/qualitative.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/qualitative" */))
const _662b3172 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/quantitative.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/quantitative" */))
const _4e3dcfc2 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/reports/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/reports/index" */))
const _b8a9aed6 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/somatotype.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/somatotype" */))
const _6435062b = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/weightLoss.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/weightLoss" */))
const _534c5770 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/zscore.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/zscore" */))
const _4b4c56d8 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/fitness/_fitnessId/functional.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/fitness/_fitnessId/functional" */))
const _25db67e6 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/skinfolds/_skinfoldId/radar.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/skinfolds/_skinfoldId/radar" */))
const _966db094 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/trainings/_trainingId/load.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/trainings/_trainingId/load" */))
const _38aa5204 = () => interopDefault(import('../pages/regional/groups/_groupId/patients/_patientId/exams/_examId/bia.vue' /* webpackChunkName: "pages/regional/groups/_groupId/patients/_patientId/exams/_examId/bia" */))
const _a2e541e8 = () => interopDefault(import('../pages/regional/groups/_groupId/patients/_patientId/exams/_examId/reports/index.vue' /* webpackChunkName: "pages/regional/groups/_groupId/patients/_patientId/exams/_examId/reports/index" */))
const _338c7da9 = () => interopDefault(import('../pages/regional/groups/_groupId/patients/_patientId/exams/_examId/vector.vue' /* webpackChunkName: "pages/regional/groups/_groupId/patients/_patientId/exams/_examId/vector" */))
const _3abd2ec6 = () => interopDefault(import('../pages/regional/groups/_groupId/patients/_patientId/histories/_historyId/attachments/index.vue' /* webpackChunkName: "pages/regional/groups/_groupId/patients/_patientId/histories/_historyId/attachments/index" */))
const _5a97ca2a = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/reports/_reportSlug/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/reports/_reportSlug/index" */))
const _658055dc = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/index" */))
const _2ce47468 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/reports/_reportSlug/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/reports/_reportSlug/index" */))
const _2d179ade = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/index" */))
const _6dc522db = () => interopDefault(import('../pages/regional/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/index.vue' /* webpackChunkName: "pages/regional/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/index" */))
const _a6f74a4a = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/reports/_reportSlug/iframe.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/reports/_reportSlug/iframe" */))
const _036fe594 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/iframe.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/iframe" */))
const _70e00c1a = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/reports/_reportSlug/iframe.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/reports/_reportSlug/iframe" */))
const _6dc737f5 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/iframe.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/iframe" */))
const _739450eb = () => interopDefault(import('../pages/regional/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/iframe.vue' /* webpackChunkName: "pages/regional/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/iframe" */))
const _57c51718 = () => interopDefault(import('../pages/groups/_groupId/index.vue' /* webpackChunkName: "pages/groups/_groupId/index" */))
const _25e66ac0 = () => interopDefault(import('../pages/groups/_groupId/bia.vue' /* webpackChunkName: "pages/groups/_groupId/bia" */))
const _2716af5f = () => interopDefault(import('../pages/groups/_groupId/deleted.vue' /* webpackChunkName: "pages/groups/_groupId/deleted" */))
const _2efe5e6b = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/index" */))
const _c59a6fb2 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/activities/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/activities/index" */))
const _6315e099 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/bodyscan/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/bodyscan/index" */))
const _688aeabd = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/grid.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/grid" */))
const _30ac4be4 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/schedule.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/schedule" */))
const _d8f92a62 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/bodyscan/grid.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/bodyscan/grid" */))
const _4f32d3d7 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/deleted.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/deleted" */))
const _362805fc = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/bodyscan/_bodyscanExamId/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/bodyscan/_bodyscanExamId/index" */))
const _b7a3d08c = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/index" */))
const _5d038494 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/overview/_formulaId/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/overview/_formulaId/index" */))
const _6303ce9c = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/bodyscan/_bodyscanExamId/reports/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/bodyscan/_bodyscanExamId/reports/index" */))
const _7709a83c = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/bia.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/bia" */))
const _f3dc3a9a = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/estimates.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/estimates" */))
const _c8fd0c6c = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/hydra.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/hydra" */))
const _75927e73 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/interpretive.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/interpretive" */))
const _ac70e0c6 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/measures.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/measures" */))
const _0b9f27be = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/percentiles.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/percentiles" */))
const _9f94092c = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/reports/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/reports/index" */))
const _5bf7dabc = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/reports/edit.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/reports/edit" */))
const _0a2ba4eb = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/reports/default/_reportSlug/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/reports/default/_reportSlug/index" */))
const _63fe10db = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/reports/default/_reportSlug/iframe.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/reports/default/_reportSlug/iframe" */))
const _6f6262bc = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/reports/default/_reportSlug/new/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/reports/default/_reportSlug/new/index" */))
const _7e5e9381 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/bodyscan/_bodyscanExamId/reports/_reportSlug/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/bodyscan/_bodyscanExamId/reports/_reportSlug/index" */))
const _5a80e969 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportId/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportId/index" */))
const _7628f505 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/bodyscan/_bodyscanExamId/reports/_reportSlug/iframe.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/bodyscan/_bodyscanExamId/reports/_reportSlug/iframe" */))
const _1e515c1d = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportId/iframe.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportId/iframe" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/academy",
    component: _689740ee,
    name: "academy___en"
  }, {
    path: "/academy-signup",
    component: _382e242a,
    name: "academy-signup___en"
  }, {
    path: "/accept",
    component: _6a88f799,
    name: "accept___en"
  }, {
    path: "/accept-contract",
    component: _e2144914,
    name: "accept-contract___en"
  }, {
    path: "/activate-secondary-mail",
    component: _c29c656e,
    name: "activate-secondary-mail___en"
  }, {
    path: "/admin",
    component: _49193f4e,
    name: "admin___en"
  }, {
    path: "/biva",
    component: _00f13574,
    name: "biva___en"
  }, {
    path: "/bluetooth",
    component: _63eabc70,
    name: "bluetooth___en"
  }, {
    path: "/completeProfile",
    component: _f2c00622,
    name: "completeProfile___en"
  }, {
    path: "/config",
    component: _3589ce5a,
    name: "config___en"
  }, {
    path: "/customer-signup",
    component: _9a303db4,
    name: "customer-signup___en"
  }, {
    path: "/de",
    component: _17a9eb51,
    name: "index___de"
  }, {
    path: "/el",
    component: _17a9eb51,
    name: "index___el"
  }, {
    path: "/en-gb",
    component: _17a9eb51,
    name: "index___en-gb"
  }, {
    path: "/es",
    component: _17a9eb51,
    name: "index___es"
  }, {
    path: "/fitness",
    component: _74143cea,
    name: "fitness___en"
  }, {
    path: "/forgot",
    component: _bd12bf98,
    name: "forgot___en"
  }, {
    path: "/forgot-password",
    component: _62bf6e44,
    name: "forgot-password___en"
  }, {
    path: "/fr",
    component: _17a9eb51,
    name: "index___fr"
  }, {
    path: "/groups",
    component: _9fdd85b0,
    name: "groups___en"
  }, {
    path: "/HBO-signup",
    component: _f289453e,
    name: "HBO-signup___en"
  }, {
    path: "/it",
    component: _17a9eb51,
    name: "index___it"
  }, {
    path: "/keto",
    component: _4b65dda9,
    name: "keto___en"
  }, {
    path: "/login",
    component: _3d5ce330,
    name: "login___en"
  }, {
    path: "/nl",
    component: _17a9eb51,
    name: "index___nl"
  }, {
    path: "/nl-be",
    component: _17a9eb51,
    name: "index___nl-be"
  }, {
    path: "/pl",
    component: _17a9eb51,
    name: "index___pl"
  }, {
    path: "/regional",
    component: _667e9d73,
    name: "regional___en"
  }, {
    path: "/reset",
    component: _7f274d64,
    name: "reset___en"
  }, {
    path: "/schedule",
    component: _09a94930,
    name: "schedule___en"
  }, {
    path: "/search",
    component: _ed5b4f8e,
    name: "search___en"
  }, {
    path: "/settings",
    component: _711e5e77,
    name: "settings___en"
  }, {
    path: "/signup",
    component: _d58ba8ae,
    name: "signup___en"
  }, {
    path: "/update-required",
    component: _f7fd753c,
    name: "update-required___en"
  }, {
    path: "/users",
    component: _27a1caca,
    name: "users___en"
  }, {
    path: "/zh",
    component: _17a9eb51,
    name: "index___zh"
  }, {
    path: "/academy/material",
    component: _17618504,
    name: "academy-material___en"
  }, {
    path: "/de/academy",
    component: _689740ee,
    name: "academy___de"
  }, {
    path: "/de/academy-signup",
    component: _382e242a,
    name: "academy-signup___de"
  }, {
    path: "/de/accept",
    component: _6a88f799,
    name: "accept___de"
  }, {
    path: "/de/accept-contract",
    component: _e2144914,
    name: "accept-contract___de"
  }, {
    path: "/de/activate-secondary-mail",
    component: _c29c656e,
    name: "activate-secondary-mail___de"
  }, {
    path: "/de/admin",
    component: _49193f4e,
    name: "admin___de"
  }, {
    path: "/de/biva",
    component: _00f13574,
    name: "biva___de"
  }, {
    path: "/de/bluetooth",
    component: _63eabc70,
    name: "bluetooth___de"
  }, {
    path: "/de/completeProfile",
    component: _f2c00622,
    name: "completeProfile___de"
  }, {
    path: "/de/config",
    component: _3589ce5a,
    name: "config___de"
  }, {
    path: "/de/customer-signup",
    component: _9a303db4,
    name: "customer-signup___de"
  }, {
    path: "/de/fitness",
    component: _74143cea,
    name: "fitness___de"
  }, {
    path: "/de/forgot",
    component: _bd12bf98,
    name: "forgot___de"
  }, {
    path: "/de/forgot-password",
    component: _62bf6e44,
    name: "forgot-password___de"
  }, {
    path: "/de/groups",
    component: _9fdd85b0,
    name: "groups___de"
  }, {
    path: "/de/HBO-signup",
    component: _f289453e,
    name: "HBO-signup___de"
  }, {
    path: "/de/keto",
    component: _4b65dda9,
    name: "keto___de"
  }, {
    path: "/de/login",
    component: _3d5ce330,
    name: "login___de"
  }, {
    path: "/de/regional",
    component: _667e9d73,
    name: "regional___de"
  }, {
    path: "/de/reset",
    component: _7f274d64,
    name: "reset___de"
  }, {
    path: "/de/schedule",
    component: _09a94930,
    name: "schedule___de"
  }, {
    path: "/de/search",
    component: _ed5b4f8e,
    name: "search___de"
  }, {
    path: "/de/settings",
    component: _711e5e77,
    name: "settings___de"
  }, {
    path: "/de/signup",
    component: _d58ba8ae,
    name: "signup___de"
  }, {
    path: "/de/update-required",
    component: _f7fd753c,
    name: "update-required___de"
  }, {
    path: "/de/users",
    component: _27a1caca,
    name: "users___de"
  }, {
    path: "/el/academy",
    component: _689740ee,
    name: "academy___el"
  }, {
    path: "/el/academy-signup",
    component: _382e242a,
    name: "academy-signup___el"
  }, {
    path: "/el/accept",
    component: _6a88f799,
    name: "accept___el"
  }, {
    path: "/el/accept-contract",
    component: _e2144914,
    name: "accept-contract___el"
  }, {
    path: "/el/activate-secondary-mail",
    component: _c29c656e,
    name: "activate-secondary-mail___el"
  }, {
    path: "/el/admin",
    component: _49193f4e,
    name: "admin___el"
  }, {
    path: "/el/biva",
    component: _00f13574,
    name: "biva___el"
  }, {
    path: "/el/bluetooth",
    component: _63eabc70,
    name: "bluetooth___el"
  }, {
    path: "/el/completeProfile",
    component: _f2c00622,
    name: "completeProfile___el"
  }, {
    path: "/el/config",
    component: _3589ce5a,
    name: "config___el"
  }, {
    path: "/el/customer-signup",
    component: _9a303db4,
    name: "customer-signup___el"
  }, {
    path: "/el/fitness",
    component: _74143cea,
    name: "fitness___el"
  }, {
    path: "/el/forgot",
    component: _bd12bf98,
    name: "forgot___el"
  }, {
    path: "/el/forgot-password",
    component: _62bf6e44,
    name: "forgot-password___el"
  }, {
    path: "/el/groups",
    component: _9fdd85b0,
    name: "groups___el"
  }, {
    path: "/el/HBO-signup",
    component: _f289453e,
    name: "HBO-signup___el"
  }, {
    path: "/el/keto",
    component: _4b65dda9,
    name: "keto___el"
  }, {
    path: "/el/login",
    component: _3d5ce330,
    name: "login___el"
  }, {
    path: "/el/regional",
    component: _667e9d73,
    name: "regional___el"
  }, {
    path: "/el/reset",
    component: _7f274d64,
    name: "reset___el"
  }, {
    path: "/el/schedule",
    component: _09a94930,
    name: "schedule___el"
  }, {
    path: "/el/search",
    component: _ed5b4f8e,
    name: "search___el"
  }, {
    path: "/el/settings",
    component: _711e5e77,
    name: "settings___el"
  }, {
    path: "/el/signup",
    component: _d58ba8ae,
    name: "signup___el"
  }, {
    path: "/el/update-required",
    component: _f7fd753c,
    name: "update-required___el"
  }, {
    path: "/el/users",
    component: _27a1caca,
    name: "users___el"
  }, {
    path: "/en-gb/academy",
    component: _689740ee,
    name: "academy___en-gb"
  }, {
    path: "/en-gb/academy-signup",
    component: _382e242a,
    name: "academy-signup___en-gb"
  }, {
    path: "/en-gb/accept",
    component: _6a88f799,
    name: "accept___en-gb"
  }, {
    path: "/en-gb/accept-contract",
    component: _e2144914,
    name: "accept-contract___en-gb"
  }, {
    path: "/en-gb/activate-secondary-mail",
    component: _c29c656e,
    name: "activate-secondary-mail___en-gb"
  }, {
    path: "/en-gb/admin",
    component: _49193f4e,
    name: "admin___en-gb"
  }, {
    path: "/en-gb/biva",
    component: _00f13574,
    name: "biva___en-gb"
  }, {
    path: "/en-gb/bluetooth",
    component: _63eabc70,
    name: "bluetooth___en-gb"
  }, {
    path: "/en-gb/completeProfile",
    component: _f2c00622,
    name: "completeProfile___en-gb"
  }, {
    path: "/en-gb/config",
    component: _3589ce5a,
    name: "config___en-gb"
  }, {
    path: "/en-gb/customer-signup",
    component: _9a303db4,
    name: "customer-signup___en-gb"
  }, {
    path: "/en-gb/fitness",
    component: _74143cea,
    name: "fitness___en-gb"
  }, {
    path: "/en-gb/forgot",
    component: _bd12bf98,
    name: "forgot___en-gb"
  }, {
    path: "/en-gb/forgot-password",
    component: _62bf6e44,
    name: "forgot-password___en-gb"
  }, {
    path: "/en-gb/groups",
    component: _9fdd85b0,
    name: "groups___en-gb"
  }, {
    path: "/en-gb/HBO-signup",
    component: _f289453e,
    name: "HBO-signup___en-gb"
  }, {
    path: "/en-gb/keto",
    component: _4b65dda9,
    name: "keto___en-gb"
  }, {
    path: "/en-gb/login",
    component: _3d5ce330,
    name: "login___en-gb"
  }, {
    path: "/en-gb/regional",
    component: _667e9d73,
    name: "regional___en-gb"
  }, {
    path: "/en-gb/reset",
    component: _7f274d64,
    name: "reset___en-gb"
  }, {
    path: "/en-gb/schedule",
    component: _09a94930,
    name: "schedule___en-gb"
  }, {
    path: "/en-gb/search",
    component: _ed5b4f8e,
    name: "search___en-gb"
  }, {
    path: "/en-gb/settings",
    component: _711e5e77,
    name: "settings___en-gb"
  }, {
    path: "/en-gb/signup",
    component: _d58ba8ae,
    name: "signup___en-gb"
  }, {
    path: "/en-gb/update-required",
    component: _f7fd753c,
    name: "update-required___en-gb"
  }, {
    path: "/en-gb/users",
    component: _27a1caca,
    name: "users___en-gb"
  }, {
    path: "/es/academy",
    component: _689740ee,
    name: "academy___es"
  }, {
    path: "/es/academy-signup",
    component: _382e242a,
    name: "academy-signup___es"
  }, {
    path: "/es/accept",
    component: _6a88f799,
    name: "accept___es"
  }, {
    path: "/es/accept-contract",
    component: _e2144914,
    name: "accept-contract___es"
  }, {
    path: "/es/activate-secondary-mail",
    component: _c29c656e,
    name: "activate-secondary-mail___es"
  }, {
    path: "/es/admin",
    component: _49193f4e,
    name: "admin___es"
  }, {
    path: "/es/biva",
    component: _00f13574,
    name: "biva___es"
  }, {
    path: "/es/bluetooth",
    component: _63eabc70,
    name: "bluetooth___es"
  }, {
    path: "/es/completeProfile",
    component: _f2c00622,
    name: "completeProfile___es"
  }, {
    path: "/es/config",
    component: _3589ce5a,
    name: "config___es"
  }, {
    path: "/es/customer-signup",
    component: _9a303db4,
    name: "customer-signup___es"
  }, {
    path: "/es/fitness",
    component: _74143cea,
    name: "fitness___es"
  }, {
    path: "/es/forgot",
    component: _bd12bf98,
    name: "forgot___es"
  }, {
    path: "/es/forgot-password",
    component: _62bf6e44,
    name: "forgot-password___es"
  }, {
    path: "/es/groups",
    component: _9fdd85b0,
    name: "groups___es"
  }, {
    path: "/es/HBO-signup",
    component: _f289453e,
    name: "HBO-signup___es"
  }, {
    path: "/es/keto",
    component: _4b65dda9,
    name: "keto___es"
  }, {
    path: "/es/login",
    component: _3d5ce330,
    name: "login___es"
  }, {
    path: "/es/regional",
    component: _667e9d73,
    name: "regional___es"
  }, {
    path: "/es/reset",
    component: _7f274d64,
    name: "reset___es"
  }, {
    path: "/es/schedule",
    component: _09a94930,
    name: "schedule___es"
  }, {
    path: "/es/search",
    component: _ed5b4f8e,
    name: "search___es"
  }, {
    path: "/es/settings",
    component: _711e5e77,
    name: "settings___es"
  }, {
    path: "/es/signup",
    component: _d58ba8ae,
    name: "signup___es"
  }, {
    path: "/es/update-required",
    component: _f7fd753c,
    name: "update-required___es"
  }, {
    path: "/es/users",
    component: _27a1caca,
    name: "users___es"
  }, {
    path: "/fitness/groups",
    component: _85a4c522,
    name: "fitness-groups___en"
  }, {
    path: "/fr/academy",
    component: _689740ee,
    name: "academy___fr"
  }, {
    path: "/fr/academy-signup",
    component: _382e242a,
    name: "academy-signup___fr"
  }, {
    path: "/fr/accept",
    component: _6a88f799,
    name: "accept___fr"
  }, {
    path: "/fr/accept-contract",
    component: _e2144914,
    name: "accept-contract___fr"
  }, {
    path: "/fr/activate-secondary-mail",
    component: _c29c656e,
    name: "activate-secondary-mail___fr"
  }, {
    path: "/fr/admin",
    component: _49193f4e,
    name: "admin___fr"
  }, {
    path: "/fr/biva",
    component: _00f13574,
    name: "biva___fr"
  }, {
    path: "/fr/bluetooth",
    component: _63eabc70,
    name: "bluetooth___fr"
  }, {
    path: "/fr/completeProfile",
    component: _f2c00622,
    name: "completeProfile___fr"
  }, {
    path: "/fr/config",
    component: _3589ce5a,
    name: "config___fr"
  }, {
    path: "/fr/customer-signup",
    component: _9a303db4,
    name: "customer-signup___fr"
  }, {
    path: "/fr/fitness",
    component: _74143cea,
    name: "fitness___fr"
  }, {
    path: "/fr/forgot",
    component: _bd12bf98,
    name: "forgot___fr"
  }, {
    path: "/fr/forgot-password",
    component: _62bf6e44,
    name: "forgot-password___fr"
  }, {
    path: "/fr/groups",
    component: _9fdd85b0,
    name: "groups___fr"
  }, {
    path: "/fr/HBO-signup",
    component: _f289453e,
    name: "HBO-signup___fr"
  }, {
    path: "/fr/keto",
    component: _4b65dda9,
    name: "keto___fr"
  }, {
    path: "/fr/login",
    component: _3d5ce330,
    name: "login___fr"
  }, {
    path: "/fr/regional",
    component: _667e9d73,
    name: "regional___fr"
  }, {
    path: "/fr/reset",
    component: _7f274d64,
    name: "reset___fr"
  }, {
    path: "/fr/schedule",
    component: _09a94930,
    name: "schedule___fr"
  }, {
    path: "/fr/search",
    component: _ed5b4f8e,
    name: "search___fr"
  }, {
    path: "/fr/settings",
    component: _711e5e77,
    name: "settings___fr"
  }, {
    path: "/fr/signup",
    component: _d58ba8ae,
    name: "signup___fr"
  }, {
    path: "/fr/update-required",
    component: _f7fd753c,
    name: "update-required___fr"
  }, {
    path: "/fr/users",
    component: _27a1caca,
    name: "users___fr"
  }, {
    path: "/groups/deleted",
    component: _c61cdd22,
    name: "groups-deleted___en"
  }, {
    path: "/it/academy",
    component: _689740ee,
    name: "academy___it"
  }, {
    path: "/it/academy-signup",
    component: _382e242a,
    name: "academy-signup___it"
  }, {
    path: "/it/accept",
    component: _6a88f799,
    name: "accept___it"
  }, {
    path: "/it/accept-contract",
    component: _e2144914,
    name: "accept-contract___it"
  }, {
    path: "/it/activate-secondary-mail",
    component: _c29c656e,
    name: "activate-secondary-mail___it"
  }, {
    path: "/it/admin",
    component: _49193f4e,
    name: "admin___it"
  }, {
    path: "/it/biva",
    component: _00f13574,
    name: "biva___it"
  }, {
    path: "/it/bluetooth",
    component: _63eabc70,
    name: "bluetooth___it"
  }, {
    path: "/it/completeProfile",
    component: _f2c00622,
    name: "completeProfile___it"
  }, {
    path: "/it/config",
    component: _3589ce5a,
    name: "config___it"
  }, {
    path: "/it/customer-signup",
    component: _9a303db4,
    name: "customer-signup___it"
  }, {
    path: "/it/fitness",
    component: _74143cea,
    name: "fitness___it"
  }, {
    path: "/it/forgot",
    component: _bd12bf98,
    name: "forgot___it"
  }, {
    path: "/it/forgot-password",
    component: _62bf6e44,
    name: "forgot-password___it"
  }, {
    path: "/it/groups",
    component: _9fdd85b0,
    name: "groups___it"
  }, {
    path: "/it/HBO-signup",
    component: _f289453e,
    name: "HBO-signup___it"
  }, {
    path: "/it/keto",
    component: _4b65dda9,
    name: "keto___it"
  }, {
    path: "/it/login",
    component: _3d5ce330,
    name: "login___it"
  }, {
    path: "/it/regional",
    component: _667e9d73,
    name: "regional___it"
  }, {
    path: "/it/reset",
    component: _7f274d64,
    name: "reset___it"
  }, {
    path: "/it/schedule",
    component: _09a94930,
    name: "schedule___it"
  }, {
    path: "/it/search",
    component: _ed5b4f8e,
    name: "search___it"
  }, {
    path: "/it/settings",
    component: _711e5e77,
    name: "settings___it"
  }, {
    path: "/it/signup",
    component: _d58ba8ae,
    name: "signup___it"
  }, {
    path: "/it/update-required",
    component: _f7fd753c,
    name: "update-required___it"
  }, {
    path: "/it/users",
    component: _27a1caca,
    name: "users___it"
  }, {
    path: "/keto/groups",
    component: _75c877d0,
    name: "keto-groups___en"
  }, {
    path: "/nl-be/academy",
    component: _689740ee,
    name: "academy___nl-be"
  }, {
    path: "/nl-be/academy-signup",
    component: _382e242a,
    name: "academy-signup___nl-be"
  }, {
    path: "/nl-be/accept",
    component: _6a88f799,
    name: "accept___nl-be"
  }, {
    path: "/nl-be/accept-contract",
    component: _e2144914,
    name: "accept-contract___nl-be"
  }, {
    path: "/nl-be/activate-secondary-mail",
    component: _c29c656e,
    name: "activate-secondary-mail___nl-be"
  }, {
    path: "/nl-be/admin",
    component: _49193f4e,
    name: "admin___nl-be"
  }, {
    path: "/nl-be/biva",
    component: _00f13574,
    name: "biva___nl-be"
  }, {
    path: "/nl-be/bluetooth",
    component: _63eabc70,
    name: "bluetooth___nl-be"
  }, {
    path: "/nl-be/completeProfile",
    component: _f2c00622,
    name: "completeProfile___nl-be"
  }, {
    path: "/nl-be/config",
    component: _3589ce5a,
    name: "config___nl-be"
  }, {
    path: "/nl-be/customer-signup",
    component: _9a303db4,
    name: "customer-signup___nl-be"
  }, {
    path: "/nl-be/fitness",
    component: _74143cea,
    name: "fitness___nl-be"
  }, {
    path: "/nl-be/forgot",
    component: _bd12bf98,
    name: "forgot___nl-be"
  }, {
    path: "/nl-be/forgot-password",
    component: _62bf6e44,
    name: "forgot-password___nl-be"
  }, {
    path: "/nl-be/groups",
    component: _9fdd85b0,
    name: "groups___nl-be"
  }, {
    path: "/nl-be/HBO-signup",
    component: _f289453e,
    name: "HBO-signup___nl-be"
  }, {
    path: "/nl-be/keto",
    component: _4b65dda9,
    name: "keto___nl-be"
  }, {
    path: "/nl-be/login",
    component: _3d5ce330,
    name: "login___nl-be"
  }, {
    path: "/nl-be/regional",
    component: _667e9d73,
    name: "regional___nl-be"
  }, {
    path: "/nl-be/reset",
    component: _7f274d64,
    name: "reset___nl-be"
  }, {
    path: "/nl-be/schedule",
    component: _09a94930,
    name: "schedule___nl-be"
  }, {
    path: "/nl-be/search",
    component: _ed5b4f8e,
    name: "search___nl-be"
  }, {
    path: "/nl-be/settings",
    component: _711e5e77,
    name: "settings___nl-be"
  }, {
    path: "/nl-be/signup",
    component: _d58ba8ae,
    name: "signup___nl-be"
  }, {
    path: "/nl-be/update-required",
    component: _f7fd753c,
    name: "update-required___nl-be"
  }, {
    path: "/nl-be/users",
    component: _27a1caca,
    name: "users___nl-be"
  }, {
    path: "/nl/academy",
    component: _689740ee,
    name: "academy___nl"
  }, {
    path: "/nl/academy-signup",
    component: _382e242a,
    name: "academy-signup___nl"
  }, {
    path: "/nl/accept",
    component: _6a88f799,
    name: "accept___nl"
  }, {
    path: "/nl/accept-contract",
    component: _e2144914,
    name: "accept-contract___nl"
  }, {
    path: "/nl/activate-secondary-mail",
    component: _c29c656e,
    name: "activate-secondary-mail___nl"
  }, {
    path: "/nl/admin",
    component: _49193f4e,
    name: "admin___nl"
  }, {
    path: "/nl/biva",
    component: _00f13574,
    name: "biva___nl"
  }, {
    path: "/nl/bluetooth",
    component: _63eabc70,
    name: "bluetooth___nl"
  }, {
    path: "/nl/completeProfile",
    component: _f2c00622,
    name: "completeProfile___nl"
  }, {
    path: "/nl/config",
    component: _3589ce5a,
    name: "config___nl"
  }, {
    path: "/nl/customer-signup",
    component: _9a303db4,
    name: "customer-signup___nl"
  }, {
    path: "/nl/fitness",
    component: _74143cea,
    name: "fitness___nl"
  }, {
    path: "/nl/forgot",
    component: _bd12bf98,
    name: "forgot___nl"
  }, {
    path: "/nl/forgot-password",
    component: _62bf6e44,
    name: "forgot-password___nl"
  }, {
    path: "/nl/groups",
    component: _9fdd85b0,
    name: "groups___nl"
  }, {
    path: "/nl/HBO-signup",
    component: _f289453e,
    name: "HBO-signup___nl"
  }, {
    path: "/nl/keto",
    component: _4b65dda9,
    name: "keto___nl"
  }, {
    path: "/nl/login",
    component: _3d5ce330,
    name: "login___nl"
  }, {
    path: "/nl/regional",
    component: _667e9d73,
    name: "regional___nl"
  }, {
    path: "/nl/reset",
    component: _7f274d64,
    name: "reset___nl"
  }, {
    path: "/nl/schedule",
    component: _09a94930,
    name: "schedule___nl"
  }, {
    path: "/nl/search",
    component: _ed5b4f8e,
    name: "search___nl"
  }, {
    path: "/nl/settings",
    component: _711e5e77,
    name: "settings___nl"
  }, {
    path: "/nl/signup",
    component: _d58ba8ae,
    name: "signup___nl"
  }, {
    path: "/nl/update-required",
    component: _f7fd753c,
    name: "update-required___nl"
  }, {
    path: "/nl/users",
    component: _27a1caca,
    name: "users___nl"
  }, {
    path: "/pl/academy",
    component: _689740ee,
    name: "academy___pl"
  }, {
    path: "/pl/academy-signup",
    component: _382e242a,
    name: "academy-signup___pl"
  }, {
    path: "/pl/accept",
    component: _6a88f799,
    name: "accept___pl"
  }, {
    path: "/pl/accept-contract",
    component: _e2144914,
    name: "accept-contract___pl"
  }, {
    path: "/pl/activate-secondary-mail",
    component: _c29c656e,
    name: "activate-secondary-mail___pl"
  }, {
    path: "/pl/admin",
    component: _49193f4e,
    name: "admin___pl"
  }, {
    path: "/pl/biva",
    component: _00f13574,
    name: "biva___pl"
  }, {
    path: "/pl/bluetooth",
    component: _63eabc70,
    name: "bluetooth___pl"
  }, {
    path: "/pl/completeProfile",
    component: _f2c00622,
    name: "completeProfile___pl"
  }, {
    path: "/pl/config",
    component: _3589ce5a,
    name: "config___pl"
  }, {
    path: "/pl/customer-signup",
    component: _9a303db4,
    name: "customer-signup___pl"
  }, {
    path: "/pl/fitness",
    component: _74143cea,
    name: "fitness___pl"
  }, {
    path: "/pl/forgot",
    component: _bd12bf98,
    name: "forgot___pl"
  }, {
    path: "/pl/forgot-password",
    component: _62bf6e44,
    name: "forgot-password___pl"
  }, {
    path: "/pl/groups",
    component: _9fdd85b0,
    name: "groups___pl"
  }, {
    path: "/pl/HBO-signup",
    component: _f289453e,
    name: "HBO-signup___pl"
  }, {
    path: "/pl/keto",
    component: _4b65dda9,
    name: "keto___pl"
  }, {
    path: "/pl/login",
    component: _3d5ce330,
    name: "login___pl"
  }, {
    path: "/pl/regional",
    component: _667e9d73,
    name: "regional___pl"
  }, {
    path: "/pl/reset",
    component: _7f274d64,
    name: "reset___pl"
  }, {
    path: "/pl/schedule",
    component: _09a94930,
    name: "schedule___pl"
  }, {
    path: "/pl/search",
    component: _ed5b4f8e,
    name: "search___pl"
  }, {
    path: "/pl/settings",
    component: _711e5e77,
    name: "settings___pl"
  }, {
    path: "/pl/signup",
    component: _d58ba8ae,
    name: "signup___pl"
  }, {
    path: "/pl/update-required",
    component: _f7fd753c,
    name: "update-required___pl"
  }, {
    path: "/pl/users",
    component: _27a1caca,
    name: "users___pl"
  }, {
    path: "/regional/groups",
    component: _27d840c6,
    name: "regional-groups___en"
  }, {
    path: "/settings/documents",
    component: _3e8656dd,
    name: "settings-documents___en"
  }, {
    path: "/settings/licenses",
    component: _0d736abd,
    name: "settings-licenses___en"
  }, {
    path: "/zh/academy",
    component: _689740ee,
    name: "academy___zh"
  }, {
    path: "/zh/academy-signup",
    component: _382e242a,
    name: "academy-signup___zh"
  }, {
    path: "/zh/accept",
    component: _6a88f799,
    name: "accept___zh"
  }, {
    path: "/zh/accept-contract",
    component: _e2144914,
    name: "accept-contract___zh"
  }, {
    path: "/zh/activate-secondary-mail",
    component: _c29c656e,
    name: "activate-secondary-mail___zh"
  }, {
    path: "/zh/admin",
    component: _49193f4e,
    name: "admin___zh"
  }, {
    path: "/zh/biva",
    component: _00f13574,
    name: "biva___zh"
  }, {
    path: "/zh/bluetooth",
    component: _63eabc70,
    name: "bluetooth___zh"
  }, {
    path: "/zh/completeProfile",
    component: _f2c00622,
    name: "completeProfile___zh"
  }, {
    path: "/zh/config",
    component: _3589ce5a,
    name: "config___zh"
  }, {
    path: "/zh/customer-signup",
    component: _9a303db4,
    name: "customer-signup___zh"
  }, {
    path: "/zh/fitness",
    component: _74143cea,
    name: "fitness___zh"
  }, {
    path: "/zh/forgot",
    component: _bd12bf98,
    name: "forgot___zh"
  }, {
    path: "/zh/forgot-password",
    component: _62bf6e44,
    name: "forgot-password___zh"
  }, {
    path: "/zh/groups",
    component: _9fdd85b0,
    name: "groups___zh"
  }, {
    path: "/zh/HBO-signup",
    component: _f289453e,
    name: "HBO-signup___zh"
  }, {
    path: "/zh/keto",
    component: _4b65dda9,
    name: "keto___zh"
  }, {
    path: "/zh/login",
    component: _3d5ce330,
    name: "login___zh"
  }, {
    path: "/zh/regional",
    component: _667e9d73,
    name: "regional___zh"
  }, {
    path: "/zh/reset",
    component: _7f274d64,
    name: "reset___zh"
  }, {
    path: "/zh/schedule",
    component: _09a94930,
    name: "schedule___zh"
  }, {
    path: "/zh/search",
    component: _ed5b4f8e,
    name: "search___zh"
  }, {
    path: "/zh/settings",
    component: _711e5e77,
    name: "settings___zh"
  }, {
    path: "/zh/signup",
    component: _d58ba8ae,
    name: "signup___zh"
  }, {
    path: "/zh/update-required",
    component: _f7fd753c,
    name: "update-required___zh"
  }, {
    path: "/zh/users",
    component: _27a1caca,
    name: "users___zh"
  }, {
    path: "/de/academy/material",
    component: _17618504,
    name: "academy-material___de"
  }, {
    path: "/de/fitness/groups",
    component: _85a4c522,
    name: "fitness-groups___de"
  }, {
    path: "/de/groups/deleted",
    component: _c61cdd22,
    name: "groups-deleted___de"
  }, {
    path: "/de/keto/groups",
    component: _75c877d0,
    name: "keto-groups___de"
  }, {
    path: "/de/regional/groups",
    component: _27d840c6,
    name: "regional-groups___de"
  }, {
    path: "/de/settings/documents",
    component: _3e8656dd,
    name: "settings-documents___de"
  }, {
    path: "/de/settings/licenses",
    component: _0d736abd,
    name: "settings-licenses___de"
  }, {
    path: "/el/academy/material",
    component: _17618504,
    name: "academy-material___el"
  }, {
    path: "/el/fitness/groups",
    component: _85a4c522,
    name: "fitness-groups___el"
  }, {
    path: "/el/groups/deleted",
    component: _c61cdd22,
    name: "groups-deleted___el"
  }, {
    path: "/el/keto/groups",
    component: _75c877d0,
    name: "keto-groups___el"
  }, {
    path: "/el/regional/groups",
    component: _27d840c6,
    name: "regional-groups___el"
  }, {
    path: "/el/settings/documents",
    component: _3e8656dd,
    name: "settings-documents___el"
  }, {
    path: "/el/settings/licenses",
    component: _0d736abd,
    name: "settings-licenses___el"
  }, {
    path: "/en-gb/academy/material",
    component: _17618504,
    name: "academy-material___en-gb"
  }, {
    path: "/en-gb/fitness/groups",
    component: _85a4c522,
    name: "fitness-groups___en-gb"
  }, {
    path: "/en-gb/groups/deleted",
    component: _c61cdd22,
    name: "groups-deleted___en-gb"
  }, {
    path: "/en-gb/keto/groups",
    component: _75c877d0,
    name: "keto-groups___en-gb"
  }, {
    path: "/en-gb/regional/groups",
    component: _27d840c6,
    name: "regional-groups___en-gb"
  }, {
    path: "/en-gb/settings/documents",
    component: _3e8656dd,
    name: "settings-documents___en-gb"
  }, {
    path: "/en-gb/settings/licenses",
    component: _0d736abd,
    name: "settings-licenses___en-gb"
  }, {
    path: "/es/academy/material",
    component: _17618504,
    name: "academy-material___es"
  }, {
    path: "/es/fitness/groups",
    component: _85a4c522,
    name: "fitness-groups___es"
  }, {
    path: "/es/groups/deleted",
    component: _c61cdd22,
    name: "groups-deleted___es"
  }, {
    path: "/es/keto/groups",
    component: _75c877d0,
    name: "keto-groups___es"
  }, {
    path: "/es/regional/groups",
    component: _27d840c6,
    name: "regional-groups___es"
  }, {
    path: "/es/settings/documents",
    component: _3e8656dd,
    name: "settings-documents___es"
  }, {
    path: "/es/settings/licenses",
    component: _0d736abd,
    name: "settings-licenses___es"
  }, {
    path: "/fitness/groups/deleted",
    component: _57120814,
    name: "fitness-groups-deleted___en"
  }, {
    path: "/fr/academy/material",
    component: _17618504,
    name: "academy-material___fr"
  }, {
    path: "/fr/fitness/groups",
    component: _85a4c522,
    name: "fitness-groups___fr"
  }, {
    path: "/fr/groups/deleted",
    component: _c61cdd22,
    name: "groups-deleted___fr"
  }, {
    path: "/fr/keto/groups",
    component: _75c877d0,
    name: "keto-groups___fr"
  }, {
    path: "/fr/regional/groups",
    component: _27d840c6,
    name: "regional-groups___fr"
  }, {
    path: "/fr/settings/documents",
    component: _3e8656dd,
    name: "settings-documents___fr"
  }, {
    path: "/fr/settings/licenses",
    component: _0d736abd,
    name: "settings-licenses___fr"
  }, {
    path: "/it/academy/material",
    component: _17618504,
    name: "academy-material___it"
  }, {
    path: "/it/fitness/groups",
    component: _85a4c522,
    name: "fitness-groups___it"
  }, {
    path: "/it/groups/deleted",
    component: _c61cdd22,
    name: "groups-deleted___it"
  }, {
    path: "/it/keto/groups",
    component: _75c877d0,
    name: "keto-groups___it"
  }, {
    path: "/it/regional/groups",
    component: _27d840c6,
    name: "regional-groups___it"
  }, {
    path: "/it/settings/documents",
    component: _3e8656dd,
    name: "settings-documents___it"
  }, {
    path: "/it/settings/licenses",
    component: _0d736abd,
    name: "settings-licenses___it"
  }, {
    path: "/keto/groups/deleted",
    component: _5c767bd2,
    name: "keto-groups-deleted___en"
  }, {
    path: "/nl-be/academy/material",
    component: _17618504,
    name: "academy-material___nl-be"
  }, {
    path: "/nl-be/fitness/groups",
    component: _85a4c522,
    name: "fitness-groups___nl-be"
  }, {
    path: "/nl-be/groups/deleted",
    component: _c61cdd22,
    name: "groups-deleted___nl-be"
  }, {
    path: "/nl-be/keto/groups",
    component: _75c877d0,
    name: "keto-groups___nl-be"
  }, {
    path: "/nl-be/regional/groups",
    component: _27d840c6,
    name: "regional-groups___nl-be"
  }, {
    path: "/nl-be/settings/documents",
    component: _3e8656dd,
    name: "settings-documents___nl-be"
  }, {
    path: "/nl-be/settings/licenses",
    component: _0d736abd,
    name: "settings-licenses___nl-be"
  }, {
    path: "/nl/academy/material",
    component: _17618504,
    name: "academy-material___nl"
  }, {
    path: "/nl/fitness/groups",
    component: _85a4c522,
    name: "fitness-groups___nl"
  }, {
    path: "/nl/groups/deleted",
    component: _c61cdd22,
    name: "groups-deleted___nl"
  }, {
    path: "/nl/keto/groups",
    component: _75c877d0,
    name: "keto-groups___nl"
  }, {
    path: "/nl/regional/groups",
    component: _27d840c6,
    name: "regional-groups___nl"
  }, {
    path: "/nl/settings/documents",
    component: _3e8656dd,
    name: "settings-documents___nl"
  }, {
    path: "/nl/settings/licenses",
    component: _0d736abd,
    name: "settings-licenses___nl"
  }, {
    path: "/pl/academy/material",
    component: _17618504,
    name: "academy-material___pl"
  }, {
    path: "/pl/fitness/groups",
    component: _85a4c522,
    name: "fitness-groups___pl"
  }, {
    path: "/pl/groups/deleted",
    component: _c61cdd22,
    name: "groups-deleted___pl"
  }, {
    path: "/pl/keto/groups",
    component: _75c877d0,
    name: "keto-groups___pl"
  }, {
    path: "/pl/regional/groups",
    component: _27d840c6,
    name: "regional-groups___pl"
  }, {
    path: "/pl/settings/documents",
    component: _3e8656dd,
    name: "settings-documents___pl"
  }, {
    path: "/pl/settings/licenses",
    component: _0d736abd,
    name: "settings-licenses___pl"
  }, {
    path: "/regional/groups/deleted",
    component: _3f06258d,
    name: "regional-groups-deleted___en"
  }, {
    path: "/zh/academy/material",
    component: _17618504,
    name: "academy-material___zh"
  }, {
    path: "/zh/fitness/groups",
    component: _85a4c522,
    name: "fitness-groups___zh"
  }, {
    path: "/zh/groups/deleted",
    component: _c61cdd22,
    name: "groups-deleted___zh"
  }, {
    path: "/zh/keto/groups",
    component: _75c877d0,
    name: "keto-groups___zh"
  }, {
    path: "/zh/regional/groups",
    component: _27d840c6,
    name: "regional-groups___zh"
  }, {
    path: "/zh/settings/documents",
    component: _3e8656dd,
    name: "settings-documents___zh"
  }, {
    path: "/zh/settings/licenses",
    component: _0d736abd,
    name: "settings-licenses___zh"
  }, {
    path: "/de/fitness/groups/deleted",
    component: _57120814,
    name: "fitness-groups-deleted___de"
  }, {
    path: "/de/keto/groups/deleted",
    component: _5c767bd2,
    name: "keto-groups-deleted___de"
  }, {
    path: "/de/regional/groups/deleted",
    component: _3f06258d,
    name: "regional-groups-deleted___de"
  }, {
    path: "/el/fitness/groups/deleted",
    component: _57120814,
    name: "fitness-groups-deleted___el"
  }, {
    path: "/el/keto/groups/deleted",
    component: _5c767bd2,
    name: "keto-groups-deleted___el"
  }, {
    path: "/el/regional/groups/deleted",
    component: _3f06258d,
    name: "regional-groups-deleted___el"
  }, {
    path: "/en-gb/fitness/groups/deleted",
    component: _57120814,
    name: "fitness-groups-deleted___en-gb"
  }, {
    path: "/en-gb/keto/groups/deleted",
    component: _5c767bd2,
    name: "keto-groups-deleted___en-gb"
  }, {
    path: "/en-gb/regional/groups/deleted",
    component: _3f06258d,
    name: "regional-groups-deleted___en-gb"
  }, {
    path: "/es/fitness/groups/deleted",
    component: _57120814,
    name: "fitness-groups-deleted___es"
  }, {
    path: "/es/keto/groups/deleted",
    component: _5c767bd2,
    name: "keto-groups-deleted___es"
  }, {
    path: "/es/regional/groups/deleted",
    component: _3f06258d,
    name: "regional-groups-deleted___es"
  }, {
    path: "/fr/fitness/groups/deleted",
    component: _57120814,
    name: "fitness-groups-deleted___fr"
  }, {
    path: "/fr/keto/groups/deleted",
    component: _5c767bd2,
    name: "keto-groups-deleted___fr"
  }, {
    path: "/fr/regional/groups/deleted",
    component: _3f06258d,
    name: "regional-groups-deleted___fr"
  }, {
    path: "/it/fitness/groups/deleted",
    component: _57120814,
    name: "fitness-groups-deleted___it"
  }, {
    path: "/it/keto/groups/deleted",
    component: _5c767bd2,
    name: "keto-groups-deleted___it"
  }, {
    path: "/it/regional/groups/deleted",
    component: _3f06258d,
    name: "regional-groups-deleted___it"
  }, {
    path: "/nl-be/fitness/groups/deleted",
    component: _57120814,
    name: "fitness-groups-deleted___nl-be"
  }, {
    path: "/nl-be/keto/groups/deleted",
    component: _5c767bd2,
    name: "keto-groups-deleted___nl-be"
  }, {
    path: "/nl-be/regional/groups/deleted",
    component: _3f06258d,
    name: "regional-groups-deleted___nl-be"
  }, {
    path: "/nl/fitness/groups/deleted",
    component: _57120814,
    name: "fitness-groups-deleted___nl"
  }, {
    path: "/nl/keto/groups/deleted",
    component: _5c767bd2,
    name: "keto-groups-deleted___nl"
  }, {
    path: "/nl/regional/groups/deleted",
    component: _3f06258d,
    name: "regional-groups-deleted___nl"
  }, {
    path: "/pl/fitness/groups/deleted",
    component: _57120814,
    name: "fitness-groups-deleted___pl"
  }, {
    path: "/pl/keto/groups/deleted",
    component: _5c767bd2,
    name: "keto-groups-deleted___pl"
  }, {
    path: "/pl/regional/groups/deleted",
    component: _3f06258d,
    name: "regional-groups-deleted___pl"
  }, {
    path: "/zh/fitness/groups/deleted",
    component: _57120814,
    name: "fitness-groups-deleted___zh"
  }, {
    path: "/zh/keto/groups/deleted",
    component: _5c767bd2,
    name: "keto-groups-deleted___zh"
  }, {
    path: "/zh/regional/groups/deleted",
    component: _3f06258d,
    name: "regional-groups-deleted___zh"
  }, {
    path: "/de/biva/regional/:sessionId?",
    component: _a112c394,
    name: "biva-regional-sessionId___de"
  }, {
    path: "/de/biva/sessions/:sessionId?",
    component: _5951f010,
    name: "biva-sessions-sessionId___de"
  }, {
    path: "/de/fitness/groups/:groupId",
    component: _d619d29e,
    name: "fitness-groups-groupId___de"
  }, {
    path: "/de/keto/groups/:groupId",
    component: _03c15970,
    name: "keto-groups-groupId___de"
  }, {
    path: "/de/regional/groups/:groupId",
    component: _5185453a,
    name: "regional-groups-groupId___de"
  }, {
    path: "/el/biva/regional/:sessionId?",
    component: _a112c394,
    name: "biva-regional-sessionId___el"
  }, {
    path: "/el/biva/sessions/:sessionId?",
    component: _5951f010,
    name: "biva-sessions-sessionId___el"
  }, {
    path: "/el/fitness/groups/:groupId",
    component: _d619d29e,
    name: "fitness-groups-groupId___el"
  }, {
    path: "/el/keto/groups/:groupId",
    component: _03c15970,
    name: "keto-groups-groupId___el"
  }, {
    path: "/el/regional/groups/:groupId",
    component: _5185453a,
    name: "regional-groups-groupId___el"
  }, {
    path: "/en-gb/biva/regional/:sessionId?",
    component: _a112c394,
    name: "biva-regional-sessionId___en-gb"
  }, {
    path: "/en-gb/biva/sessions/:sessionId?",
    component: _5951f010,
    name: "biva-sessions-sessionId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId",
    component: _d619d29e,
    name: "fitness-groups-groupId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId",
    component: _03c15970,
    name: "keto-groups-groupId___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId",
    component: _5185453a,
    name: "regional-groups-groupId___en-gb"
  }, {
    path: "/es/biva/regional/:sessionId?",
    component: _a112c394,
    name: "biva-regional-sessionId___es"
  }, {
    path: "/es/biva/sessions/:sessionId?",
    component: _5951f010,
    name: "biva-sessions-sessionId___es"
  }, {
    path: "/es/fitness/groups/:groupId",
    component: _d619d29e,
    name: "fitness-groups-groupId___es"
  }, {
    path: "/es/keto/groups/:groupId",
    component: _03c15970,
    name: "keto-groups-groupId___es"
  }, {
    path: "/es/regional/groups/:groupId",
    component: _5185453a,
    name: "regional-groups-groupId___es"
  }, {
    path: "/fr/biva/regional/:sessionId?",
    component: _a112c394,
    name: "biva-regional-sessionId___fr"
  }, {
    path: "/fr/biva/sessions/:sessionId?",
    component: _5951f010,
    name: "biva-sessions-sessionId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId",
    component: _d619d29e,
    name: "fitness-groups-groupId___fr"
  }, {
    path: "/fr/keto/groups/:groupId",
    component: _03c15970,
    name: "keto-groups-groupId___fr"
  }, {
    path: "/fr/regional/groups/:groupId",
    component: _5185453a,
    name: "regional-groups-groupId___fr"
  }, {
    path: "/it/biva/regional/:sessionId?",
    component: _a112c394,
    name: "biva-regional-sessionId___it"
  }, {
    path: "/it/biva/sessions/:sessionId?",
    component: _5951f010,
    name: "biva-sessions-sessionId___it"
  }, {
    path: "/it/fitness/groups/:groupId",
    component: _d619d29e,
    name: "fitness-groups-groupId___it"
  }, {
    path: "/it/keto/groups/:groupId",
    component: _03c15970,
    name: "keto-groups-groupId___it"
  }, {
    path: "/it/regional/groups/:groupId",
    component: _5185453a,
    name: "regional-groups-groupId___it"
  }, {
    path: "/nl-be/biva/regional/:sessionId?",
    component: _a112c394,
    name: "biva-regional-sessionId___nl-be"
  }, {
    path: "/nl-be/biva/sessions/:sessionId?",
    component: _5951f010,
    name: "biva-sessions-sessionId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId",
    component: _d619d29e,
    name: "fitness-groups-groupId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId",
    component: _03c15970,
    name: "keto-groups-groupId___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId",
    component: _5185453a,
    name: "regional-groups-groupId___nl-be"
  }, {
    path: "/nl/biva/regional/:sessionId?",
    component: _a112c394,
    name: "biva-regional-sessionId___nl"
  }, {
    path: "/nl/biva/sessions/:sessionId?",
    component: _5951f010,
    name: "biva-sessions-sessionId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId",
    component: _d619d29e,
    name: "fitness-groups-groupId___nl"
  }, {
    path: "/nl/keto/groups/:groupId",
    component: _03c15970,
    name: "keto-groups-groupId___nl"
  }, {
    path: "/nl/regional/groups/:groupId",
    component: _5185453a,
    name: "regional-groups-groupId___nl"
  }, {
    path: "/pl/biva/regional/:sessionId?",
    component: _a112c394,
    name: "biva-regional-sessionId___pl"
  }, {
    path: "/pl/biva/sessions/:sessionId?",
    component: _5951f010,
    name: "biva-sessions-sessionId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId",
    component: _d619d29e,
    name: "fitness-groups-groupId___pl"
  }, {
    path: "/pl/keto/groups/:groupId",
    component: _03c15970,
    name: "keto-groups-groupId___pl"
  }, {
    path: "/pl/regional/groups/:groupId",
    component: _5185453a,
    name: "regional-groups-groupId___pl"
  }, {
    path: "/zh/biva/regional/:sessionId?",
    component: _a112c394,
    name: "biva-regional-sessionId___zh"
  }, {
    path: "/zh/biva/sessions/:sessionId?",
    component: _5951f010,
    name: "biva-sessions-sessionId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId",
    component: _d619d29e,
    name: "fitness-groups-groupId___zh"
  }, {
    path: "/zh/keto/groups/:groupId",
    component: _03c15970,
    name: "keto-groups-groupId___zh"
  }, {
    path: "/zh/regional/groups/:groupId",
    component: _5185453a,
    name: "regional-groups-groupId___zh"
  }, {
    path: "/de/fitness/groups/:groupId/deleted",
    component: _5e79a690,
    name: "fitness-groups-groupId-deleted___de"
  }, {
    path: "/de/keto/groups/:groupId/deleted",
    component: _75e88892,
    name: "keto-groups-groupId-deleted___de"
  }, {
    path: "/de/regional/groups/:groupId/deleted",
    component: _9cf845fe,
    name: "regional-groups-groupId-deleted___de"
  }, {
    path: "/el/fitness/groups/:groupId/deleted",
    component: _5e79a690,
    name: "fitness-groups-groupId-deleted___el"
  }, {
    path: "/el/keto/groups/:groupId/deleted",
    component: _75e88892,
    name: "keto-groups-groupId-deleted___el"
  }, {
    path: "/el/regional/groups/:groupId/deleted",
    component: _9cf845fe,
    name: "regional-groups-groupId-deleted___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/deleted",
    component: _5e79a690,
    name: "fitness-groups-groupId-deleted___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/deleted",
    component: _75e88892,
    name: "keto-groups-groupId-deleted___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/deleted",
    component: _9cf845fe,
    name: "regional-groups-groupId-deleted___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/deleted",
    component: _5e79a690,
    name: "fitness-groups-groupId-deleted___es"
  }, {
    path: "/es/keto/groups/:groupId/deleted",
    component: _75e88892,
    name: "keto-groups-groupId-deleted___es"
  }, {
    path: "/es/regional/groups/:groupId/deleted",
    component: _9cf845fe,
    name: "regional-groups-groupId-deleted___es"
  }, {
    path: "/fr/fitness/groups/:groupId/deleted",
    component: _5e79a690,
    name: "fitness-groups-groupId-deleted___fr"
  }, {
    path: "/fr/keto/groups/:groupId/deleted",
    component: _75e88892,
    name: "keto-groups-groupId-deleted___fr"
  }, {
    path: "/fr/regional/groups/:groupId/deleted",
    component: _9cf845fe,
    name: "regional-groups-groupId-deleted___fr"
  }, {
    path: "/it/fitness/groups/:groupId/deleted",
    component: _5e79a690,
    name: "fitness-groups-groupId-deleted___it"
  }, {
    path: "/it/keto/groups/:groupId/deleted",
    component: _75e88892,
    name: "keto-groups-groupId-deleted___it"
  }, {
    path: "/it/regional/groups/:groupId/deleted",
    component: _9cf845fe,
    name: "regional-groups-groupId-deleted___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/deleted",
    component: _5e79a690,
    name: "fitness-groups-groupId-deleted___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/deleted",
    component: _75e88892,
    name: "keto-groups-groupId-deleted___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/deleted",
    component: _9cf845fe,
    name: "regional-groups-groupId-deleted___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/deleted",
    component: _5e79a690,
    name: "fitness-groups-groupId-deleted___nl"
  }, {
    path: "/nl/keto/groups/:groupId/deleted",
    component: _75e88892,
    name: "keto-groups-groupId-deleted___nl"
  }, {
    path: "/nl/regional/groups/:groupId/deleted",
    component: _9cf845fe,
    name: "regional-groups-groupId-deleted___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/deleted",
    component: _5e79a690,
    name: "fitness-groups-groupId-deleted___pl"
  }, {
    path: "/pl/keto/groups/:groupId/deleted",
    component: _75e88892,
    name: "keto-groups-groupId-deleted___pl"
  }, {
    path: "/pl/regional/groups/:groupId/deleted",
    component: _9cf845fe,
    name: "regional-groups-groupId-deleted___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/deleted",
    component: _5e79a690,
    name: "fitness-groups-groupId-deleted___zh"
  }, {
    path: "/zh/keto/groups/:groupId/deleted",
    component: _75e88892,
    name: "keto-groups-groupId-deleted___zh"
  }, {
    path: "/zh/regional/groups/:groupId/deleted",
    component: _9cf845fe,
    name: "regional-groups-groupId-deleted___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?",
    component: _10bcd0f8,
    name: "fitness-groups-groupId-patients-patientId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?",
    component: _6e4bf67a,
    name: "keto-groups-groupId-patients-patientId___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?",
    component: _1da7b0e6,
    name: "regional-groups-groupId-patients-patientId___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?",
    component: _10bcd0f8,
    name: "fitness-groups-groupId-patients-patientId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?",
    component: _6e4bf67a,
    name: "keto-groups-groupId-patients-patientId___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?",
    component: _1da7b0e6,
    name: "regional-groups-groupId-patients-patientId___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?",
    component: _10bcd0f8,
    name: "fitness-groups-groupId-patients-patientId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?",
    component: _6e4bf67a,
    name: "keto-groups-groupId-patients-patientId___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?",
    component: _1da7b0e6,
    name: "regional-groups-groupId-patients-patientId___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?",
    component: _10bcd0f8,
    name: "fitness-groups-groupId-patients-patientId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?",
    component: _6e4bf67a,
    name: "keto-groups-groupId-patients-patientId___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?",
    component: _1da7b0e6,
    name: "regional-groups-groupId-patients-patientId___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?",
    component: _10bcd0f8,
    name: "fitness-groups-groupId-patients-patientId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?",
    component: _6e4bf67a,
    name: "keto-groups-groupId-patients-patientId___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?",
    component: _1da7b0e6,
    name: "regional-groups-groupId-patients-patientId___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?",
    component: _10bcd0f8,
    name: "fitness-groups-groupId-patients-patientId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?",
    component: _6e4bf67a,
    name: "keto-groups-groupId-patients-patientId___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?",
    component: _1da7b0e6,
    name: "regional-groups-groupId-patients-patientId___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?",
    component: _10bcd0f8,
    name: "fitness-groups-groupId-patients-patientId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?",
    component: _6e4bf67a,
    name: "keto-groups-groupId-patients-patientId___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?",
    component: _1da7b0e6,
    name: "regional-groups-groupId-patients-patientId___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?",
    component: _10bcd0f8,
    name: "fitness-groups-groupId-patients-patientId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?",
    component: _6e4bf67a,
    name: "keto-groups-groupId-patients-patientId___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?",
    component: _1da7b0e6,
    name: "regional-groups-groupId-patients-patientId___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?",
    component: _10bcd0f8,
    name: "fitness-groups-groupId-patients-patientId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?",
    component: _6e4bf67a,
    name: "keto-groups-groupId-patients-patientId___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?",
    component: _1da7b0e6,
    name: "regional-groups-groupId-patients-patientId___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?",
    component: _10bcd0f8,
    name: "fitness-groups-groupId-patients-patientId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?",
    component: _6e4bf67a,
    name: "keto-groups-groupId-patients-patientId___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?",
    component: _1da7b0e6,
    name: "regional-groups-groupId-patients-patientId___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _554ca73a,
    name: "fitness-groups-groupId-patients-patientId-somatotype___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _72deae84,
    name: "keto-groups-groupId-patients-patientId-somatotype___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _554ca73a,
    name: "fitness-groups-groupId-patients-patientId-somatotype___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _72deae84,
    name: "keto-groups-groupId-patients-patientId-somatotype___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _554ca73a,
    name: "fitness-groups-groupId-patients-patientId-somatotype___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _72deae84,
    name: "keto-groups-groupId-patients-patientId-somatotype___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _554ca73a,
    name: "fitness-groups-groupId-patients-patientId-somatotype___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _72deae84,
    name: "keto-groups-groupId-patients-patientId-somatotype___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _554ca73a,
    name: "fitness-groups-groupId-patients-patientId-somatotype___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _72deae84,
    name: "keto-groups-groupId-patients-patientId-somatotype___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _554ca73a,
    name: "fitness-groups-groupId-patients-patientId-somatotype___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _72deae84,
    name: "keto-groups-groupId-patients-patientId-somatotype___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _554ca73a,
    name: "fitness-groups-groupId-patients-patientId-somatotype___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _72deae84,
    name: "keto-groups-groupId-patients-patientId-somatotype___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _554ca73a,
    name: "fitness-groups-groupId-patients-patientId-somatotype___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _72deae84,
    name: "keto-groups-groupId-patients-patientId-somatotype___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _554ca73a,
    name: "fitness-groups-groupId-patients-patientId-somatotype___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _72deae84,
    name: "keto-groups-groupId-patients-patientId-somatotype___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _554ca73a,
    name: "fitness-groups-groupId-patients-patientId-somatotype___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _72deae84,
    name: "keto-groups-groupId-patients-patientId-somatotype___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _7c3be3f0,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _358ea22f,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _009e5af9,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _7c3be3f0,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _358ea22f,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _009e5af9,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _7c3be3f0,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _358ea22f,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _009e5af9,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _7c3be3f0,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _358ea22f,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _009e5af9,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _7c3be3f0,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _358ea22f,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _009e5af9,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _7c3be3f0,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _358ea22f,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _009e5af9,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _7c3be3f0,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _358ea22f,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _009e5af9,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _7c3be3f0,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _358ea22f,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _009e5af9,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _7c3be3f0,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _358ea22f,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _009e5af9,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _7c3be3f0,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _358ea22f,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _009e5af9,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _4ec84528,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _4fdbd738,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _ed3af368,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _96b21dda,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _28a8582c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _455d53b3,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6c599233,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1c8502cd,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _da5ca04e,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _bf879da6,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _e5c877dc,
    name: "keto-groups-groupId-patients-patientId-exams-examId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _f4eae6e6,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _3d0ab7b2,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6406f632,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _6da78548,
    name: "regional-groups-groupId-patients-patientId-exams-examId___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _4ec84528,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _4fdbd738,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _ed3af368,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _96b21dda,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _28a8582c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _455d53b3,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6c599233,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1c8502cd,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _da5ca04e,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _bf879da6,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _e5c877dc,
    name: "keto-groups-groupId-patients-patientId-exams-examId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _f4eae6e6,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _3d0ab7b2,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6406f632,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _6da78548,
    name: "regional-groups-groupId-patients-patientId-exams-examId___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _4ec84528,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _4fdbd738,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _ed3af368,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _96b21dda,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _28a8582c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _455d53b3,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6c599233,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1c8502cd,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _da5ca04e,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _bf879da6,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _e5c877dc,
    name: "keto-groups-groupId-patients-patientId-exams-examId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _f4eae6e6,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _3d0ab7b2,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6406f632,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _6da78548,
    name: "regional-groups-groupId-patients-patientId-exams-examId___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _4ec84528,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _4fdbd738,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _ed3af368,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _96b21dda,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _28a8582c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _455d53b3,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6c599233,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1c8502cd,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _da5ca04e,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _bf879da6,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _e5c877dc,
    name: "keto-groups-groupId-patients-patientId-exams-examId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _f4eae6e6,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _3d0ab7b2,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6406f632,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _6da78548,
    name: "regional-groups-groupId-patients-patientId-exams-examId___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _4ec84528,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _4fdbd738,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _ed3af368,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _96b21dda,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _28a8582c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _455d53b3,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6c599233,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1c8502cd,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _da5ca04e,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _bf879da6,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _e5c877dc,
    name: "keto-groups-groupId-patients-patientId-exams-examId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _f4eae6e6,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _3d0ab7b2,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6406f632,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _6da78548,
    name: "regional-groups-groupId-patients-patientId-exams-examId___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _4ec84528,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _4fdbd738,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _ed3af368,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _96b21dda,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _28a8582c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _455d53b3,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6c599233,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1c8502cd,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _da5ca04e,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _bf879da6,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _e5c877dc,
    name: "keto-groups-groupId-patients-patientId-exams-examId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _f4eae6e6,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _3d0ab7b2,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6406f632,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _6da78548,
    name: "regional-groups-groupId-patients-patientId-exams-examId___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _4ec84528,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _4fdbd738,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _ed3af368,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _96b21dda,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _28a8582c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _455d53b3,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6c599233,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1c8502cd,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _da5ca04e,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _bf879da6,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _e5c877dc,
    name: "keto-groups-groupId-patients-patientId-exams-examId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _f4eae6e6,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _3d0ab7b2,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6406f632,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _6da78548,
    name: "regional-groups-groupId-patients-patientId-exams-examId___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _4ec84528,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _4fdbd738,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _ed3af368,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _96b21dda,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _28a8582c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _455d53b3,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6c599233,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1c8502cd,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _da5ca04e,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _bf879da6,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _e5c877dc,
    name: "keto-groups-groupId-patients-patientId-exams-examId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _f4eae6e6,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _3d0ab7b2,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6406f632,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _6da78548,
    name: "regional-groups-groupId-patients-patientId-exams-examId___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _4ec84528,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _4fdbd738,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _ed3af368,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _96b21dda,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _28a8582c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _455d53b3,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6c599233,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1c8502cd,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _da5ca04e,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _bf879da6,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _e5c877dc,
    name: "keto-groups-groupId-patients-patientId-exams-examId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _f4eae6e6,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _3d0ab7b2,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6406f632,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _6da78548,
    name: "regional-groups-groupId-patients-patientId-exams-examId___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _4ec84528,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _4fdbd738,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _ed3af368,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _96b21dda,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _28a8582c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _455d53b3,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6c599233,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1c8502cd,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _da5ca04e,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _bf879da6,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _e5c877dc,
    name: "keto-groups-groupId-patients-patientId-exams-examId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _f4eae6e6,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _3d0ab7b2,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6406f632,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _6da78548,
    name: "regional-groups-groupId-patients-patientId-exams-examId___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _64a2b614,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _9f681470,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _cd5bef6c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _54ac4fe5,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _13f5f4ad,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2eb821c8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _07ad4ed8,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _72333680,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _27dc877b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _c8670974,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _2c2e1281,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _2e15befa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _921053dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _14310499,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _22bdccb5,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _550dca4c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1fc3c726,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _56906bc3,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _26619ae9,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _46c8f434,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _bf977132,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _ed3012ce,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _2e2e03e7,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _07a28795,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _54b08096,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _3ed80507,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _4e933fee,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _d0bbaef4,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _2fbb302c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _4586237d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _56c3a8da,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _ede63d3e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _61180e3a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0b28bea7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a5bcb9ea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _eddf723c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _32178738,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _13da0a73,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _581a29fa,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _496eb9bc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _10f31dd6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d71bbbea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _662b3172,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4e3dcfc2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _b8a9aed6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _6435062b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _534c5770,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _4b4c56d8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _25db67e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _966db094,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _38aa5204,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _a2e541e8,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _338c7da9,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _3abd2ec6,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _64a2b614,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _9f681470,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _cd5bef6c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _54ac4fe5,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _13f5f4ad,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2eb821c8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _07ad4ed8,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _72333680,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _27dc877b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _c8670974,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _2c2e1281,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _2e15befa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _921053dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _14310499,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _22bdccb5,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _550dca4c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1fc3c726,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _56906bc3,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _26619ae9,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _46c8f434,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _bf977132,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _ed3012ce,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _2e2e03e7,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _07a28795,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _54b08096,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _3ed80507,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _4e933fee,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _d0bbaef4,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _2fbb302c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _4586237d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _56c3a8da,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _ede63d3e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _61180e3a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0b28bea7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a5bcb9ea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _eddf723c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _32178738,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _13da0a73,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _581a29fa,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _496eb9bc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _10f31dd6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d71bbbea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _662b3172,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4e3dcfc2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _b8a9aed6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _6435062b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _534c5770,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _4b4c56d8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _25db67e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _966db094,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _38aa5204,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _a2e541e8,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _338c7da9,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _3abd2ec6,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _64a2b614,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _9f681470,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _cd5bef6c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _54ac4fe5,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _13f5f4ad,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2eb821c8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _07ad4ed8,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _72333680,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _27dc877b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _c8670974,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _2c2e1281,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _2e15befa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _921053dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _14310499,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _22bdccb5,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _550dca4c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1fc3c726,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _56906bc3,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _26619ae9,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _46c8f434,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _bf977132,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _ed3012ce,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _2e2e03e7,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _07a28795,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _54b08096,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _3ed80507,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _4e933fee,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _d0bbaef4,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _2fbb302c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _4586237d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _56c3a8da,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _ede63d3e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _61180e3a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0b28bea7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a5bcb9ea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _eddf723c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _32178738,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _13da0a73,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _581a29fa,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _496eb9bc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _10f31dd6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d71bbbea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _662b3172,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4e3dcfc2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _b8a9aed6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _6435062b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _534c5770,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _4b4c56d8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _25db67e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _966db094,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _38aa5204,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _a2e541e8,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _338c7da9,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _3abd2ec6,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _64a2b614,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _9f681470,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _cd5bef6c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _54ac4fe5,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _13f5f4ad,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2eb821c8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _07ad4ed8,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _72333680,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _27dc877b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _c8670974,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _2c2e1281,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _2e15befa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _921053dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _14310499,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _22bdccb5,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _550dca4c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1fc3c726,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _56906bc3,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _26619ae9,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _46c8f434,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _bf977132,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _ed3012ce,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _2e2e03e7,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _07a28795,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _54b08096,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _3ed80507,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _4e933fee,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _d0bbaef4,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _2fbb302c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _4586237d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _56c3a8da,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _ede63d3e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _61180e3a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0b28bea7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a5bcb9ea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _eddf723c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _32178738,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _13da0a73,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _581a29fa,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _496eb9bc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _10f31dd6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d71bbbea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _662b3172,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4e3dcfc2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _b8a9aed6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _6435062b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _534c5770,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _4b4c56d8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _25db67e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _966db094,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _38aa5204,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _a2e541e8,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _338c7da9,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _3abd2ec6,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _64a2b614,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _9f681470,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _cd5bef6c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _54ac4fe5,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _13f5f4ad,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2eb821c8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _07ad4ed8,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _72333680,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _27dc877b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _c8670974,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _2c2e1281,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _2e15befa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _921053dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _14310499,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _22bdccb5,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _550dca4c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1fc3c726,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _56906bc3,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _26619ae9,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _46c8f434,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _bf977132,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _ed3012ce,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _2e2e03e7,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _07a28795,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _54b08096,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _3ed80507,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _4e933fee,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _d0bbaef4,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _2fbb302c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _4586237d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _56c3a8da,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _ede63d3e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _61180e3a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0b28bea7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a5bcb9ea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _eddf723c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _32178738,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _13da0a73,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _581a29fa,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _496eb9bc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _10f31dd6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d71bbbea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _662b3172,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4e3dcfc2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _b8a9aed6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _6435062b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _534c5770,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _4b4c56d8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _25db67e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _966db094,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _38aa5204,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _a2e541e8,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _338c7da9,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _3abd2ec6,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _64a2b614,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _9f681470,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _cd5bef6c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _54ac4fe5,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _13f5f4ad,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2eb821c8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _07ad4ed8,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _72333680,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _27dc877b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _c8670974,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _2c2e1281,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _2e15befa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _921053dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _14310499,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _22bdccb5,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _550dca4c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1fc3c726,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _56906bc3,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _26619ae9,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _46c8f434,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _bf977132,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _ed3012ce,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _2e2e03e7,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _07a28795,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _54b08096,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _3ed80507,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _4e933fee,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _d0bbaef4,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _2fbb302c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _4586237d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _56c3a8da,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _ede63d3e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _61180e3a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0b28bea7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a5bcb9ea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _eddf723c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _32178738,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _13da0a73,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _581a29fa,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _496eb9bc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _10f31dd6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d71bbbea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _662b3172,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4e3dcfc2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _b8a9aed6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _6435062b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _534c5770,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _4b4c56d8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _25db67e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _966db094,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _38aa5204,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _a2e541e8,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _338c7da9,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _3abd2ec6,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _64a2b614,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _9f681470,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _cd5bef6c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _54ac4fe5,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _13f5f4ad,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2eb821c8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _07ad4ed8,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _72333680,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _27dc877b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _c8670974,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _2c2e1281,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _2e15befa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _921053dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _14310499,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _22bdccb5,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _550dca4c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1fc3c726,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _56906bc3,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _26619ae9,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _46c8f434,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _bf977132,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _ed3012ce,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _2e2e03e7,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _07a28795,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _54b08096,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _3ed80507,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _4e933fee,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _d0bbaef4,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _2fbb302c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _4586237d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _56c3a8da,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _ede63d3e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _61180e3a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0b28bea7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a5bcb9ea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _eddf723c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _32178738,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _13da0a73,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _581a29fa,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _496eb9bc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _10f31dd6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d71bbbea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _662b3172,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4e3dcfc2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _b8a9aed6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _6435062b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _534c5770,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _4b4c56d8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _25db67e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _966db094,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _38aa5204,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _a2e541e8,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _338c7da9,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _3abd2ec6,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _64a2b614,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _9f681470,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _cd5bef6c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _54ac4fe5,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _13f5f4ad,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2eb821c8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _07ad4ed8,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _72333680,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _27dc877b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _c8670974,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _2c2e1281,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _2e15befa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _921053dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _14310499,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _22bdccb5,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _550dca4c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1fc3c726,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _56906bc3,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _26619ae9,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _46c8f434,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _bf977132,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _ed3012ce,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _2e2e03e7,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _07a28795,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _54b08096,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _3ed80507,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _4e933fee,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _d0bbaef4,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _2fbb302c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _4586237d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _56c3a8da,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _ede63d3e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _61180e3a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0b28bea7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a5bcb9ea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _eddf723c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _32178738,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _13da0a73,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _581a29fa,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _496eb9bc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _10f31dd6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d71bbbea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _662b3172,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4e3dcfc2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _b8a9aed6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _6435062b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _534c5770,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _4b4c56d8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _25db67e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _966db094,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _38aa5204,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _a2e541e8,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _338c7da9,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _3abd2ec6,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _64a2b614,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _9f681470,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _cd5bef6c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _54ac4fe5,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _13f5f4ad,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2eb821c8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _07ad4ed8,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _72333680,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _27dc877b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _c8670974,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _2c2e1281,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _2e15befa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _921053dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _14310499,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _22bdccb5,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _550dca4c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1fc3c726,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _56906bc3,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _26619ae9,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _46c8f434,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _bf977132,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _ed3012ce,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _2e2e03e7,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _07a28795,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _54b08096,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _3ed80507,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _4e933fee,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _d0bbaef4,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _2fbb302c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _4586237d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _56c3a8da,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _ede63d3e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _61180e3a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0b28bea7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a5bcb9ea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _eddf723c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _32178738,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _13da0a73,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _581a29fa,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _496eb9bc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _10f31dd6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d71bbbea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _662b3172,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4e3dcfc2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _b8a9aed6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _6435062b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _534c5770,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _4b4c56d8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _25db67e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _966db094,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _38aa5204,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _a2e541e8,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _338c7da9,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _3abd2ec6,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _64a2b614,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _9f681470,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _cd5bef6c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _54ac4fe5,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _13f5f4ad,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2eb821c8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _07ad4ed8,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _72333680,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _27dc877b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _c8670974,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _2c2e1281,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _2e15befa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _921053dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _14310499,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _22bdccb5,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _550dca4c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1fc3c726,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _56906bc3,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _26619ae9,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _46c8f434,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _bf977132,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _ed3012ce,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _2e2e03e7,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _07a28795,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _54b08096,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _3ed80507,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _4e933fee,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _d0bbaef4,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _2fbb302c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _4586237d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _56c3a8da,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _ede63d3e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _61180e3a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0b28bea7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a5bcb9ea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _eddf723c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _32178738,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _13da0a73,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _581a29fa,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _496eb9bc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _10f31dd6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d71bbbea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _662b3172,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4e3dcfc2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _b8a9aed6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _6435062b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _534c5770,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _4b4c56d8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _25db67e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _966db094,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _38aa5204,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _a2e541e8,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _338c7da9,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _3abd2ec6,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _5a97ca2a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _658055dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2ce47468,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _2d179ade,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6dc522db,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _5a97ca2a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _658055dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2ce47468,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _2d179ade,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6dc522db,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _5a97ca2a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _658055dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2ce47468,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _2d179ade,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6dc522db,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _5a97ca2a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _658055dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2ce47468,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _2d179ade,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6dc522db,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _5a97ca2a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _658055dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2ce47468,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _2d179ade,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6dc522db,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _5a97ca2a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _658055dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2ce47468,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _2d179ade,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6dc522db,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _5a97ca2a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _658055dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2ce47468,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _2d179ade,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6dc522db,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _5a97ca2a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _658055dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2ce47468,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _2d179ade,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6dc522db,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _5a97ca2a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _658055dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2ce47468,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _2d179ade,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6dc522db,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _5a97ca2a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _658055dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2ce47468,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _2d179ade,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6dc522db,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _a6f74a4a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _036fe594,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _70e00c1a,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6dc737f5,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _739450eb,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _a6f74a4a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _036fe594,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _70e00c1a,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6dc737f5,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _739450eb,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _a6f74a4a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _036fe594,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _70e00c1a,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6dc737f5,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _739450eb,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _a6f74a4a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _036fe594,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _70e00c1a,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6dc737f5,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _739450eb,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _a6f74a4a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _036fe594,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _70e00c1a,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6dc737f5,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _739450eb,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _a6f74a4a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _036fe594,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _70e00c1a,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6dc737f5,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _739450eb,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _a6f74a4a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _036fe594,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _70e00c1a,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6dc737f5,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _739450eb,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _a6f74a4a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _036fe594,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _70e00c1a,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6dc737f5,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _739450eb,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _a6f74a4a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _036fe594,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _70e00c1a,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6dc737f5,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _739450eb,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _a6f74a4a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _036fe594,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _70e00c1a,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6dc737f5,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _739450eb,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___zh"
  }, {
    path: "/biva/regional/:sessionId?",
    component: _a112c394,
    name: "biva-regional-sessionId___en"
  }, {
    path: "/biva/sessions/:sessionId?",
    component: _5951f010,
    name: "biva-sessions-sessionId___en"
  }, {
    path: "/de/groups/:groupId",
    component: _57c51718,
    name: "groups-groupId___de"
  }, {
    path: "/el/groups/:groupId",
    component: _57c51718,
    name: "groups-groupId___el"
  }, {
    path: "/en-gb/groups/:groupId",
    component: _57c51718,
    name: "groups-groupId___en-gb"
  }, {
    path: "/es/groups/:groupId",
    component: _57c51718,
    name: "groups-groupId___es"
  }, {
    path: "/fitness/groups/:groupId",
    component: _d619d29e,
    name: "fitness-groups-groupId___en"
  }, {
    path: "/fr/groups/:groupId",
    component: _57c51718,
    name: "groups-groupId___fr"
  }, {
    path: "/it/groups/:groupId",
    component: _57c51718,
    name: "groups-groupId___it"
  }, {
    path: "/keto/groups/:groupId",
    component: _03c15970,
    name: "keto-groups-groupId___en"
  }, {
    path: "/nl-be/groups/:groupId",
    component: _57c51718,
    name: "groups-groupId___nl-be"
  }, {
    path: "/nl/groups/:groupId",
    component: _57c51718,
    name: "groups-groupId___nl"
  }, {
    path: "/pl/groups/:groupId",
    component: _57c51718,
    name: "groups-groupId___pl"
  }, {
    path: "/regional/groups/:groupId",
    component: _5185453a,
    name: "regional-groups-groupId___en"
  }, {
    path: "/zh/groups/:groupId",
    component: _57c51718,
    name: "groups-groupId___zh"
  }, {
    path: "/de/groups/:groupId/bia",
    component: _25e66ac0,
    name: "groups-groupId-bia___de"
  }, {
    path: "/de/groups/:groupId/deleted",
    component: _2716af5f,
    name: "groups-groupId-deleted___de"
  }, {
    path: "/el/groups/:groupId/bia",
    component: _25e66ac0,
    name: "groups-groupId-bia___el"
  }, {
    path: "/el/groups/:groupId/deleted",
    component: _2716af5f,
    name: "groups-groupId-deleted___el"
  }, {
    path: "/en-gb/groups/:groupId/bia",
    component: _25e66ac0,
    name: "groups-groupId-bia___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/deleted",
    component: _2716af5f,
    name: "groups-groupId-deleted___en-gb"
  }, {
    path: "/es/groups/:groupId/bia",
    component: _25e66ac0,
    name: "groups-groupId-bia___es"
  }, {
    path: "/es/groups/:groupId/deleted",
    component: _2716af5f,
    name: "groups-groupId-deleted___es"
  }, {
    path: "/fitness/groups/:groupId/deleted",
    component: _5e79a690,
    name: "fitness-groups-groupId-deleted___en"
  }, {
    path: "/fr/groups/:groupId/bia",
    component: _25e66ac0,
    name: "groups-groupId-bia___fr"
  }, {
    path: "/fr/groups/:groupId/deleted",
    component: _2716af5f,
    name: "groups-groupId-deleted___fr"
  }, {
    path: "/it/groups/:groupId/bia",
    component: _25e66ac0,
    name: "groups-groupId-bia___it"
  }, {
    path: "/it/groups/:groupId/deleted",
    component: _2716af5f,
    name: "groups-groupId-deleted___it"
  }, {
    path: "/keto/groups/:groupId/deleted",
    component: _75e88892,
    name: "keto-groups-groupId-deleted___en"
  }, {
    path: "/nl-be/groups/:groupId/bia",
    component: _25e66ac0,
    name: "groups-groupId-bia___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/deleted",
    component: _2716af5f,
    name: "groups-groupId-deleted___nl-be"
  }, {
    path: "/nl/groups/:groupId/bia",
    component: _25e66ac0,
    name: "groups-groupId-bia___nl"
  }, {
    path: "/nl/groups/:groupId/deleted",
    component: _2716af5f,
    name: "groups-groupId-deleted___nl"
  }, {
    path: "/pl/groups/:groupId/bia",
    component: _25e66ac0,
    name: "groups-groupId-bia___pl"
  }, {
    path: "/pl/groups/:groupId/deleted",
    component: _2716af5f,
    name: "groups-groupId-deleted___pl"
  }, {
    path: "/regional/groups/:groupId/deleted",
    component: _9cf845fe,
    name: "regional-groups-groupId-deleted___en"
  }, {
    path: "/zh/groups/:groupId/bia",
    component: _25e66ac0,
    name: "groups-groupId-bia___zh"
  }, {
    path: "/zh/groups/:groupId/deleted",
    component: _2716af5f,
    name: "groups-groupId-deleted___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?",
    component: _2efe5e6b,
    name: "groups-groupId-patients-patientId___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?",
    component: _2efe5e6b,
    name: "groups-groupId-patients-patientId___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?",
    component: _2efe5e6b,
    name: "groups-groupId-patients-patientId___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?",
    component: _2efe5e6b,
    name: "groups-groupId-patients-patientId___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?",
    component: _10bcd0f8,
    name: "fitness-groups-groupId-patients-patientId___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?",
    component: _2efe5e6b,
    name: "groups-groupId-patients-patientId___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?",
    component: _2efe5e6b,
    name: "groups-groupId-patients-patientId___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?",
    component: _6e4bf67a,
    name: "keto-groups-groupId-patients-patientId___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?",
    component: _2efe5e6b,
    name: "groups-groupId-patients-patientId___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?",
    component: _2efe5e6b,
    name: "groups-groupId-patients-patientId___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?",
    component: _2efe5e6b,
    name: "groups-groupId-patients-patientId___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?",
    component: _1da7b0e6,
    name: "regional-groups-groupId-patients-patientId___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?",
    component: _2efe5e6b,
    name: "groups-groupId-patients-patientId___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/activities",
    component: _c59a6fb2,
    name: "groups-groupId-patients-patientId-activities___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan",
    component: _6315e099,
    name: "groups-groupId-patients-patientId-bodyscan___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/grid",
    component: _688aeabd,
    name: "groups-groupId-patients-patientId-grid___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/schedule",
    component: _30ac4be4,
    name: "groups-groupId-patients-patientId-schedule___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/activities",
    component: _c59a6fb2,
    name: "groups-groupId-patients-patientId-activities___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/bodyscan",
    component: _6315e099,
    name: "groups-groupId-patients-patientId-bodyscan___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/grid",
    component: _688aeabd,
    name: "groups-groupId-patients-patientId-grid___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/schedule",
    component: _30ac4be4,
    name: "groups-groupId-patients-patientId-schedule___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/activities",
    component: _c59a6fb2,
    name: "groups-groupId-patients-patientId-activities___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan",
    component: _6315e099,
    name: "groups-groupId-patients-patientId-bodyscan___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/grid",
    component: _688aeabd,
    name: "groups-groupId-patients-patientId-grid___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/schedule",
    component: _30ac4be4,
    name: "groups-groupId-patients-patientId-schedule___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/activities",
    component: _c59a6fb2,
    name: "groups-groupId-patients-patientId-activities___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan",
    component: _6315e099,
    name: "groups-groupId-patients-patientId-bodyscan___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/grid",
    component: _688aeabd,
    name: "groups-groupId-patients-patientId-grid___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/schedule",
    component: _30ac4be4,
    name: "groups-groupId-patients-patientId-schedule___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _554ca73a,
    name: "fitness-groups-groupId-patients-patientId-somatotype___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/activities",
    component: _c59a6fb2,
    name: "groups-groupId-patients-patientId-activities___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan",
    component: _6315e099,
    name: "groups-groupId-patients-patientId-bodyscan___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/grid",
    component: _688aeabd,
    name: "groups-groupId-patients-patientId-grid___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/schedule",
    component: _30ac4be4,
    name: "groups-groupId-patients-patientId-schedule___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/activities",
    component: _c59a6fb2,
    name: "groups-groupId-patients-patientId-activities___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan",
    component: _6315e099,
    name: "groups-groupId-patients-patientId-bodyscan___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/grid",
    component: _688aeabd,
    name: "groups-groupId-patients-patientId-grid___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/schedule",
    component: _30ac4be4,
    name: "groups-groupId-patients-patientId-schedule___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _72deae84,
    name: "keto-groups-groupId-patients-patientId-somatotype___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/activities",
    component: _c59a6fb2,
    name: "groups-groupId-patients-patientId-activities___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan",
    component: _6315e099,
    name: "groups-groupId-patients-patientId-bodyscan___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/grid",
    component: _688aeabd,
    name: "groups-groupId-patients-patientId-grid___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/schedule",
    component: _30ac4be4,
    name: "groups-groupId-patients-patientId-schedule___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/activities",
    component: _c59a6fb2,
    name: "groups-groupId-patients-patientId-activities___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan",
    component: _6315e099,
    name: "groups-groupId-patients-patientId-bodyscan___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/grid",
    component: _688aeabd,
    name: "groups-groupId-patients-patientId-grid___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/schedule",
    component: _30ac4be4,
    name: "groups-groupId-patients-patientId-schedule___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/activities",
    component: _c59a6fb2,
    name: "groups-groupId-patients-patientId-activities___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan",
    component: _6315e099,
    name: "groups-groupId-patients-patientId-bodyscan___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/grid",
    component: _688aeabd,
    name: "groups-groupId-patients-patientId-grid___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/schedule",
    component: _30ac4be4,
    name: "groups-groupId-patients-patientId-schedule___pl"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/activities",
    component: _c59a6fb2,
    name: "groups-groupId-patients-patientId-activities___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan",
    component: _6315e099,
    name: "groups-groupId-patients-patientId-bodyscan___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/grid",
    component: _688aeabd,
    name: "groups-groupId-patients-patientId-grid___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/schedule",
    component: _30ac4be4,
    name: "groups-groupId-patients-patientId-schedule___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _d8f92a62,
    name: "groups-groupId-patients-patientId-bodyscan-grid___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4f32d3d7,
    name: "groups-groupId-patients-patientId-exams-deleted___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _d8f92a62,
    name: "groups-groupId-patients-patientId-bodyscan-grid___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4f32d3d7,
    name: "groups-groupId-patients-patientId-exams-deleted___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _d8f92a62,
    name: "groups-groupId-patients-patientId-bodyscan-grid___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4f32d3d7,
    name: "groups-groupId-patients-patientId-exams-deleted___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _d8f92a62,
    name: "groups-groupId-patients-patientId-bodyscan-grid___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4f32d3d7,
    name: "groups-groupId-patients-patientId-exams-deleted___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _7c3be3f0,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _d8f92a62,
    name: "groups-groupId-patients-patientId-bodyscan-grid___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4f32d3d7,
    name: "groups-groupId-patients-patientId-exams-deleted___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _d8f92a62,
    name: "groups-groupId-patients-patientId-bodyscan-grid___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4f32d3d7,
    name: "groups-groupId-patients-patientId-exams-deleted___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _358ea22f,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _d8f92a62,
    name: "groups-groupId-patients-patientId-bodyscan-grid___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4f32d3d7,
    name: "groups-groupId-patients-patientId-exams-deleted___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _d8f92a62,
    name: "groups-groupId-patients-patientId-bodyscan-grid___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4f32d3d7,
    name: "groups-groupId-patients-patientId-exams-deleted___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _d8f92a62,
    name: "groups-groupId-patients-patientId-bodyscan-grid___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4f32d3d7,
    name: "groups-groupId-patients-patientId-exams-deleted___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _009e5af9,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _d8f92a62,
    name: "groups-groupId-patients-patientId-bodyscan-grid___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4f32d3d7,
    name: "groups-groupId-patients-patientId-exams-deleted___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _362805fc,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b7a3d08c,
    name: "groups-groupId-patients-patientId-exams-examId___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _5d038494,
    name: "groups-groupId-patients-patientId-overview-formulaId___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _362805fc,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b7a3d08c,
    name: "groups-groupId-patients-patientId-exams-examId___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _5d038494,
    name: "groups-groupId-patients-patientId-overview-formulaId___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _362805fc,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b7a3d08c,
    name: "groups-groupId-patients-patientId-exams-examId___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _5d038494,
    name: "groups-groupId-patients-patientId-overview-formulaId___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _362805fc,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b7a3d08c,
    name: "groups-groupId-patients-patientId-exams-examId___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _5d038494,
    name: "groups-groupId-patients-patientId-overview-formulaId___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _4ec84528,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _4fdbd738,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _ed3af368,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _96b21dda,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _28a8582c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _455d53b3,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6c599233,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _362805fc,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b7a3d08c,
    name: "groups-groupId-patients-patientId-exams-examId___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _5d038494,
    name: "groups-groupId-patients-patientId-overview-formulaId___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _362805fc,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b7a3d08c,
    name: "groups-groupId-patients-patientId-exams-examId___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _5d038494,
    name: "groups-groupId-patients-patientId-overview-formulaId___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1c8502cd,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _da5ca04e,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _bf879da6,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _e5c877dc,
    name: "keto-groups-groupId-patients-patientId-exams-examId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _f4eae6e6,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _3d0ab7b2,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _6406f632,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _362805fc,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b7a3d08c,
    name: "groups-groupId-patients-patientId-exams-examId___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _5d038494,
    name: "groups-groupId-patients-patientId-overview-formulaId___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _362805fc,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b7a3d08c,
    name: "groups-groupId-patients-patientId-exams-examId___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _5d038494,
    name: "groups-groupId-patients-patientId-overview-formulaId___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _362805fc,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b7a3d08c,
    name: "groups-groupId-patients-patientId-exams-examId___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _5d038494,
    name: "groups-groupId-patients-patientId-overview-formulaId___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _6da78548,
    name: "regional-groups-groupId-patients-patientId-exams-examId___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _362805fc,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b7a3d08c,
    name: "groups-groupId-patients-patientId-exams-examId___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _5d038494,
    name: "groups-groupId-patients-patientId-overview-formulaId___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _6303ce9c,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7709a83c,
    name: "groups-groupId-patients-patientId-exams-examId-bia___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _f3dc3a9a,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _c8fd0c6c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _75927e73,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _ac70e0c6,
    name: "groups-groupId-patients-patientId-exams-examId-measures___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _0b9f27be,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f94092c,
    name: "groups-groupId-patients-patientId-exams-examId-reports___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _6303ce9c,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7709a83c,
    name: "groups-groupId-patients-patientId-exams-examId-bia___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _f3dc3a9a,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _c8fd0c6c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _75927e73,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _ac70e0c6,
    name: "groups-groupId-patients-patientId-exams-examId-measures___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _0b9f27be,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f94092c,
    name: "groups-groupId-patients-patientId-exams-examId-reports___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _6303ce9c,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7709a83c,
    name: "groups-groupId-patients-patientId-exams-examId-bia___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _f3dc3a9a,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _c8fd0c6c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _75927e73,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _ac70e0c6,
    name: "groups-groupId-patients-patientId-exams-examId-measures___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _0b9f27be,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f94092c,
    name: "groups-groupId-patients-patientId-exams-examId-reports___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _6303ce9c,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7709a83c,
    name: "groups-groupId-patients-patientId-exams-examId-bia___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _f3dc3a9a,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _c8fd0c6c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _75927e73,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _ac70e0c6,
    name: "groups-groupId-patients-patientId-exams-examId-measures___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _0b9f27be,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f94092c,
    name: "groups-groupId-patients-patientId-exams-examId-reports___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _64a2b614,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _9f681470,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _cd5bef6c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _54ac4fe5,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _13f5f4ad,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _2eb821c8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _07ad4ed8,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _72333680,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _27dc877b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _c8670974,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _2c2e1281,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _2e15befa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _921053dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _14310499,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _22bdccb5,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _550dca4c,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1fc3c726,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _56906bc3,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _26619ae9,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _46c8f434,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _bf977132,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _ed3012ce,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _2e2e03e7,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _07a28795,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _6303ce9c,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7709a83c,
    name: "groups-groupId-patients-patientId-exams-examId-bia___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _f3dc3a9a,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _c8fd0c6c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _75927e73,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _ac70e0c6,
    name: "groups-groupId-patients-patientId-exams-examId-measures___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _0b9f27be,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f94092c,
    name: "groups-groupId-patients-patientId-exams-examId-reports___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _6303ce9c,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7709a83c,
    name: "groups-groupId-patients-patientId-exams-examId-bia___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _f3dc3a9a,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _c8fd0c6c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _75927e73,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _ac70e0c6,
    name: "groups-groupId-patients-patientId-exams-examId-measures___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _0b9f27be,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f94092c,
    name: "groups-groupId-patients-patientId-exams-examId-reports___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _54b08096,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _3ed80507,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _4e933fee,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _d0bbaef4,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _2fbb302c,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _4586237d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _56c3a8da,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _ede63d3e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _61180e3a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0b28bea7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _a5bcb9ea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _eddf723c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _32178738,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _13da0a73,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _581a29fa,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _496eb9bc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _10f31dd6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d71bbbea,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _662b3172,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4e3dcfc2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _b8a9aed6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _6435062b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _534c5770,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _4b4c56d8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _25db67e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _966db094,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _6303ce9c,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7709a83c,
    name: "groups-groupId-patients-patientId-exams-examId-bia___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _f3dc3a9a,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _c8fd0c6c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _75927e73,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _ac70e0c6,
    name: "groups-groupId-patients-patientId-exams-examId-measures___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _0b9f27be,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f94092c,
    name: "groups-groupId-patients-patientId-exams-examId-reports___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _6303ce9c,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7709a83c,
    name: "groups-groupId-patients-patientId-exams-examId-bia___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _f3dc3a9a,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _c8fd0c6c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _75927e73,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _ac70e0c6,
    name: "groups-groupId-patients-patientId-exams-examId-measures___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _0b9f27be,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f94092c,
    name: "groups-groupId-patients-patientId-exams-examId-reports___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _6303ce9c,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7709a83c,
    name: "groups-groupId-patients-patientId-exams-examId-bia___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _f3dc3a9a,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _c8fd0c6c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _75927e73,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _ac70e0c6,
    name: "groups-groupId-patients-patientId-exams-examId-measures___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _0b9f27be,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f94092c,
    name: "groups-groupId-patients-patientId-exams-examId-reports___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _38aa5204,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___en"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _a2e541e8,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___en"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _338c7da9,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___en"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _3abd2ec6,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _6303ce9c,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7709a83c,
    name: "groups-groupId-patients-patientId-exams-examId-bia___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _f3dc3a9a,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _c8fd0c6c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _75927e73,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _ac70e0c6,
    name: "groups-groupId-patients-patientId-exams-examId-measures___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _0b9f27be,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f94092c,
    name: "groups-groupId-patients-patientId-exams-examId-reports___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _5bf7dabc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _5bf7dabc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _5bf7dabc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _5bf7dabc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___es"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _5bf7dabc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _5bf7dabc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___it"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _5bf7dabc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _5bf7dabc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _5bf7dabc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___pl"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _5bf7dabc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _0a2ba4eb,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _0a2ba4eb,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _0a2ba4eb,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _0a2ba4eb,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___es"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _0a2ba4eb,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _0a2ba4eb,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___it"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _0a2ba4eb,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _0a2ba4eb,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _0a2ba4eb,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___pl"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _0a2ba4eb,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _63fe10db,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _6f6262bc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _63fe10db,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _6f6262bc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _63fe10db,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _6f6262bc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _63fe10db,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _6f6262bc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___es"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _63fe10db,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _6f6262bc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _63fe10db,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _6f6262bc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___it"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _63fe10db,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _6f6262bc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _63fe10db,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _6f6262bc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _63fe10db,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _6f6262bc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___pl"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _63fe10db,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _6f6262bc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _7e5e9381,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5a80e969,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _7e5e9381,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5a80e969,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _7e5e9381,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5a80e969,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _7e5e9381,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5a80e969,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _5a97ca2a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _658055dc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _7e5e9381,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5a80e969,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _7e5e9381,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5a80e969,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2ce47468,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _2d179ade,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _7e5e9381,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5a80e969,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _7e5e9381,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5a80e969,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _7e5e9381,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5a80e969,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6dc522db,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _7e5e9381,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5a80e969,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _7628f505,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _1e515c1d,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _7628f505,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _1e515c1d,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _7628f505,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _1e515c1d,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _7628f505,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _1e515c1d,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _a6f74a4a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _036fe594,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _7628f505,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _1e515c1d,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _7628f505,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _1e515c1d,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _70e00c1a,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _6dc737f5,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _7628f505,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _1e515c1d,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _7628f505,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _1e515c1d,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _7628f505,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _1e515c1d,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _739450eb,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _7628f505,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _1e515c1d,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___zh"
  }, {
    path: "/groups/:groupId",
    component: _57c51718,
    name: "groups-groupId___en"
  }, {
    path: "/groups/:groupId/bia",
    component: _25e66ac0,
    name: "groups-groupId-bia___en"
  }, {
    path: "/groups/:groupId/deleted",
    component: _2716af5f,
    name: "groups-groupId-deleted___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?",
    component: _2efe5e6b,
    name: "groups-groupId-patients-patientId___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/activities",
    component: _c59a6fb2,
    name: "groups-groupId-patients-patientId-activities___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan",
    component: _6315e099,
    name: "groups-groupId-patients-patientId-bodyscan___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/grid",
    component: _688aeabd,
    name: "groups-groupId-patients-patientId-grid___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/schedule",
    component: _30ac4be4,
    name: "groups-groupId-patients-patientId-schedule___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _d8f92a62,
    name: "groups-groupId-patients-patientId-bodyscan-grid___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4f32d3d7,
    name: "groups-groupId-patients-patientId-exams-deleted___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _362805fc,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b7a3d08c,
    name: "groups-groupId-patients-patientId-exams-examId___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _5d038494,
    name: "groups-groupId-patients-patientId-overview-formulaId___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _6303ce9c,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7709a83c,
    name: "groups-groupId-patients-patientId-exams-examId-bia___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _f3dc3a9a,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _c8fd0c6c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _75927e73,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _ac70e0c6,
    name: "groups-groupId-patients-patientId-exams-examId-measures___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _0b9f27be,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f94092c,
    name: "groups-groupId-patients-patientId-exams-examId-reports___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _5bf7dabc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _0a2ba4eb,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _63fe10db,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _6f6262bc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _7e5e9381,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5a80e969,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _7628f505,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _1e515c1d,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___en"
  }, {
    path: "/",
    component: _17a9eb51,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
