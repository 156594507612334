import localec28654ec from '../../lang/en-US.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en"},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","file":"en-US.json","name":"English"},{"code":"de","iso":"de-DE","file":"de-DE.json","name":"Deutsch"},{"code":"it","iso":"it-IT","file":"it-IT.json","name":"Italiano"},{"code":"nl","iso":"nl-NL","file":"nl-NL.json","name":"Dutch"},{"code":"nl-be","iso":"nl-BE","file":"nl-BE.json","name":"Flemish"},{"code":"es","iso":"es-ES","file":"es-ES.json","name":"Spanish"},{"code":"el","iso":"el-GR","file":"el-GR.json","name":"Greek"},{"code":"en-gb","iso":"en-GB","file":"en-GB.json","name":"United Kingdom"},{"code":"fr","iso":"fr-FR","file":"fr-FR.json","name":"French"},{"code":"pl","iso":"pl-PL","file":"pl-PL.json","name":"Polish"},{"code":"zh","iso":"zh-CN","file":"zh-CN.json","name":"Chinese"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/tmp/tmp-165188lkGGvgPzajl/lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en-US","file":"en-US.json","name":"English"},{"code":"de","iso":"de-DE","file":"de-DE.json","name":"Deutsch"},{"code":"it","iso":"it-IT","file":"it-IT.json","name":"Italiano"},{"code":"nl","iso":"nl-NL","file":"nl-NL.json","name":"Dutch"},{"code":"nl-be","iso":"nl-BE","file":"nl-BE.json","name":"Flemish"},{"code":"es","iso":"es-ES","file":"es-ES.json","name":"Spanish"},{"code":"el","iso":"el-GR","file":"el-GR.json","name":"Greek"},{"code":"en-gb","iso":"en-GB","file":"en-GB.json","name":"United Kingdom"},{"code":"fr","iso":"fr-FR","file":"fr-FR.json","name":"French"},{"code":"pl","iso":"pl-PL","file":"pl-PL.json","name":"Polish"},{"code":"zh","iso":"zh-CN","file":"zh-CN.json","name":"Chinese"}],
  localeCodes: ["en","de","it","nl","nl-be","es","el","en-gb","fr","pl","zh"],
}

export const localeMessages = {
  'en-US.json': () => Promise.resolve(localec28654ec),
  'de-DE.json': () => import('../../lang/de-DE.json' /* webpackChunkName: "lang-de-DE.json" */),
  'it-IT.json': () => import('../../lang/it-IT.json' /* webpackChunkName: "lang-it-IT.json" */),
  'nl-NL.json': () => import('../../lang/nl-NL.json' /* webpackChunkName: "lang-nl-NL.json" */),
  'nl-BE.json': () => import('../../lang/nl-BE.json' /* webpackChunkName: "lang-nl-BE.json" */),
  'es-ES.json': () => import('../../lang/es-ES.json' /* webpackChunkName: "lang-es-ES.json" */),
  'el-GR.json': () => import('../../lang/el-GR.json' /* webpackChunkName: "lang-el-GR.json" */),
  'en-GB.json': () => import('../../lang/en-GB.json' /* webpackChunkName: "lang-en-GB.json" */),
  'fr-FR.json': () => import('../../lang/fr-FR.json' /* webpackChunkName: "lang-fr-FR.json" */),
  'pl-PL.json': () => import('../../lang/pl-PL.json' /* webpackChunkName: "lang-pl-PL.json" */),
  'zh-CN.json': () => import('../../lang/zh-CN.json' /* webpackChunkName: "lang-zh-CN.json" */),
}
