const state = () => ({
  report: null,
  list: [],
  defaultList: [
    {
      slug: 'body-composition',
      title: 'Body composition',
      name: 'bodyComposition',
      offline: true,
      json: [
        {
          items: [
            {
              title: 'Header',
              component: 'ReportHeader',
              bg: 'dark-gray',
              image: 'block'
            }
          ]
        },
        {
          items: [
            {
              title: 'Biavector®',
              component: 'Biavector',
              bg: 'warning',
              image: 'ellipse'
            }
          ]
        },
        {
          items: [
            {
              title: 'SubjectInfo',
              component: 'ReportSubject',
              bg: 'warning',
              image: 'ellipse'
            }
          ]
        },
        {
          items: [
            {
              title: 'Estimates',
              component: 'EstimatesBodyComposition',
              bg: 'warning',
              image: 'estimates'
            }
          ]
        },
        {
          items: [
            {
              title: 'Footer',
              component: 'ReportFooter',
              bg: 'dark-gray',
              image: 'block'
            }
          ]
        }
      ]
    },
    {
      slug: 'body-composition-summary',
      title: 'Body composition summary',
      name: 'bodyCompositionSummary',
      offline: true,
      json: [
        {
          items: [
            {
              title: 'Header',
              component: 'ReportHeader',
              bg: 'dark-gray',
              image: 'block'
            }
          ]
        },
        {
          items: [
            {
              component: 'BodyCompositionSummaryDesc'
            }
          ]
        },
        {
          items: [
            {
              title: 'Biavector®',
              component: 'Biavector',
              bg: 'warning',
              image: 'ellipse'
            }
          ]
        },
        {
          items: [
            {
              title: 'Estimates simple',
              component: 'EstimatesSummary',
              bg: 'warning',
              image: 'estimates'
            }
          ]
        },
        {
          items: [
            {
              component: 'Note'
            }
          ]
        },
        {
          items: [
            {
              title: 'Footer',
              component: 'ReportFooter',
              bg: 'dark-gray',
              image: 'block'
            }
          ]
        }
      ]
    },
    {
      slug: 'hydragram-and-nutrigram',
      title: 'Hydragram® and Nutrigram®',
      name: 'hydragramAndNutrigram',
      adultOnly: true,
      notForSport: true,
      offline: true,
      json: [
        {
          items: [
            {
              title: 'Header',
              component: 'ReportHeader',
              bg: 'dark-gray',
              image: 'block'
            }
          ]
        },
        {
          items: [
            {
              title: 'Hydragram®',
              component: 'Hydragram',
              bg: 'warning',
              image: 'estimates-vertical'
            }
          ]
        },
        {
          items: [
            {
              title: 'Nutrigram®',
              component: 'Nutrigram',
              bg: 'warning',
              image: 'estimates-vertical'
            }
          ]
        },
        {
          items: [
            {
              component: 'Note'
            }
          ]
        },
        {
          items: [
            {
              title: 'Footer',
              component: 'ReportFooter',
              bg: 'dark-gray',
              image: 'block'
            }
          ]
        }
      ]
    },
    {
      slug: 'indexes-and-percentiles',
      title: 'Indexes and Percentiles',
      name: 'indexesAndPercentiles',
      adultOnly: true,
      notForSport: true,
      offline: true,
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'Percentiles'
            }
          ]
        },
        {
          items: [
            {
              component: 'Note'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    },
    {
      slug: 'followup',
      title: 'Follow up',
      name: 'followUp',
      adultOnly: true,
      notForSport: true,
      offline: true,
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'FollowupDesc'
            }
          ]
        },
        {
          items: [
            {
              component: 'FollowupSettings',
              cols: 12
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    },
    {
      slug: 'anthropometric',
      title: 'Anthropometric',
      name: 'anthropometric',
      adultOnly: true,
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'Anthropometric'
            }
          ]
        },
        {
          items: [
            {
              component: 'Note'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    },
    {
      slug: 'anthropometric-followup',
      title: 'Anthropometric Follow up',
      name: 'anthropometricFollowup',
      adultOnly: true,
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'AnthropometricFollowup'
            }
          ]
        },
        {
          items: [
            {
              component: 'Note'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    },
    {
      slug: 'database',
      title: 'Database',
      name: 'database',
      offline: true,
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'BiavectorMulti'
            }
          ]
        },
        {
          items: [
            {
              component: 'DatabaseTable'
            }
          ]
        },
        {
          items: [
            {
              component: 'Note'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    },
    {
      slug: 'weight-loss',
      title: 'Weight loss',
      name: 'weightLoss',
      adultOnly: true,
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'WeightLoss'
            }
          ]
        },
        {
          items: [
            {
              component: 'Note'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    },
    {
      slug: 'compare',
      title: 'Compare with baseline test',
      name: 'compare',
      offline: true,
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'Biavector'
            }
          ]
        },
        {
          items: [
            {
              component: 'CompareTable'
            }
          ]
        },
        {
          items: [
            {
              component: 'Note'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    },
    {
      slug: 'height',
      title: 'Height',
      name: 'height',
      adultOnly: true,
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'HeightDesc'
            }
          ]
        },
        {
          items: [
            {
              component: 'BiavectorWithTable'
            }
          ]
        },
        {
          items: [
            {
              component: 'Height'
            }
          ]
        },
        {
          items: [
            {
              component: 'Note'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    },
    {
      slug: 'percentiles',
      title: 'Percentiles',
      name: 'percentiles',
      childOnly: true,
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'ChildGrowthStandard'
            }
          ]
        },
        {
          items: [
            {
              component: 'Note'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    }
  ],
  chineseDefaultList: [
    {
      slug: 'chineseEstimates',
      title: 'chineseEstimates',
      name: 'chineseEstimates',
      offline: true,
      json: [
        {
          items: [
            {
              title: 'Header',
              component: 'ChineseReportHeader',
              bg: 'dark-gray',
              image: 'block'
            }
          ]
        },
        {
          items: [
            {
              title: 'Estimates',
              component: 'ChineseEstimates',
              bg: 'warning',
              image: 'estimates-vertical'
            }
          ]
        },
        {
          items: [
            {
              title: 'EstimatesBia',
              component: 'ChineseEstimatesBia',
              bg: 'warning',
              image: 'estimates-vertical'
            }
          ]
        }
      ]
    },
    {
      slug: 'chineseNutrigram',
      title: 'chineseNutrigram',
      name: 'chineseNutrigram',
      offline: true,
      json: [
        {
          items: [
            {
              title: 'Header',
              component: 'ChineseReportHeader',
              bg: 'dark-gray',
              image: 'block'
            }
          ]
        },
        {
          items: [
            {
              title: 'Nutrigram',
              component: 'ChineseNutrigram',
              bg: 'warning',
              image: 'nutrigram-vertical'
            }
          ]
        }
      ]
    },
    {
      slug: 'chineseBiavector',
      title: 'chineseBiavector',
      name: 'chineseBiavector',
      offline: true,
      json: [
        {
          items: [
            {
              title: 'Header',
              component: 'ChineseReportHeader',
              bg: 'dark-gray',
              image: 'block'
            }
          ]
        },
        {
          items: [
            {
              component: 'ChineseBiavector'
            }
          ]
        }
      ]
    },
    {
      slug: 'new-body-composition',
      title: 'Body composition',
      name: 'newBodyComposition',
      offline: true,
      json: [
        {
          bg: 'images/akern-bg.png',
          items: [
            {
              title: 'FirstPage',
              component: 'FirstPage',
              bg: 'dark-gray',
              image: 'block'
            }
          ]
        },
        {
          bg: 'images/akern-bg.png',
          items: [
            {
              title: 'BodyComposition',
              component: 'BodyComposition',
              bg: 'dark-gray',
              image: 'block'
            }
          ]
        },
        {
          items: [
            {
              title: 'Glossary',
              component: 'Glossary',
              bg: 'dark-gray',
              image: 'block'
            }
          ]
        }
      ]
    }
  ]
})
const getters = {
  report: state => state.report,
  list: state => state.list,

  defaultList: (state, _getters, _rootState, rootGetters) => {
    const reportList = [
      'body-composition',
      'body-composition-summary',
      'compare',
      'hydragram-and-nutrigram',
      'indexes-and-percentiles',
      'followup',
      'database'
    ]

    if (rootGetters['config/type'] === 'shared') {
      return state.defaultList.filter(report =>
        reportList.includes(report.slug)
      )
    } else {
      return state.defaultList.filter(
        report => report.name !== 'newBodyComposition'
      )
    }
  },

  chineseReportList: (state, _getters, _rootState, rootGetters) => {
    const chineseReports = [
      'chineseEstimates',
      'chineseNutrigram',
      'chineseBiavector'
    ]

    if (
      rootGetters['config/type'] === 'shared' &&
      rootGetters['user/settings'].locale === 'zh-CN'
    ) {
      return state.chineseDefaultList.filter(report => {
        return chineseReports.includes(report.slug)
      })
    }
  }
}
const mutations = {
  receiveReport(state, report) {
    state.report = report
  },
  receiveList(state, reports) {
    state.list = reports
  }
}
const actions = {
  getReport({ state, commit, rootState, rootGetters }, reportId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/templates/list', {
        accessToken: rootState.user.token
        // id: reportId
      })
      .then(response => {
        const template = response.data.templates.find(report => {
          if (rootGetters['config/type'] === 'shared') {
            return report.id === reportId
          } else {
            return report.id === +reportId
          }
        })
        const json = JSON.parse(template.json)
        json.pop()
        template.json = json
        commit('receiveReport', template)
      })
  },
  getDefaultReport({ state, commit }, reportSlug) {
    const report =
      state.defaultList.find(report => report.slug === reportSlug) ||
      state.chineseDefaultList.find(report => report.slug === reportSlug)
    commit('receiveReport', report)
  },
  getList({ commit, rootState }) {
    return this.$axios
      .post('/bodygram-backend/v1/app/templates/list', {
        accessToken: rootState.user.token
      })
      .then(response => {
        commit('receiveList', response.data.templates)
      })
  },
  createReport({ state, dispatch, rootState }, report) {
    return this.$axios
      .post('/bodygram-backend/v1/app/templates/create', {
        accessToken: rootState.user.token,
        template: report
      })
      .then(() => {
        dispatch('getList')
      })
  },
  updateReport({ state, dispatch, rootState }, report) {
    return this.$axios
      .post('/bodygram-backend/v1/app/templates/update', {
        accessToken: rootState.user.token,
        template: {
          id: report.id,
          title: report.title,
          json: report.json
        }
      })
      .then(() => {
        dispatch('getList')
      })
  },
  deleteReport({ dispatch, rootState }, reportId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/templates/delete', {
        accessToken: rootState.user.token,
        id: reportId
      })
      .then(() => {
        dispatch('getList')
      })
  }
}
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
