export const state = () => {
  return {
    printableValues:
      JSON.parse(localStorage.getItem('akern_customer_printableValues')) || null
  }
}

export const getters = {
  printableValues: state => state.printableValues
}

export const mutations = {
  setPrintableValues(state, printableValues) {
    state.printableValues = printableValues
  }
}

export const actions = {
  setPrintableValues({ state, commit }, values) {
    localStorage.setItem(
      'akern_customer_printableValues',
      JSON.stringify(values)
    )

    commit('setPrintableValues', values)
  },
  togglePrintStatus({ getters, commit }, value) {
    if (
      JSON.parse(
        localStorage.getItem('akern_customer_printableValues')
      ).includes(value)
    ) {
      const index = JSON.parse(
        localStorage.getItem('akern_customer_printableValues')
      ).indexOf(value)

      const values = JSON.parse(
        localStorage.getItem('akern_customer_printableValues')
      ).filter((item, i) => i !== index)

      localStorage.setItem(
        'akern_customer_printableValues',
        JSON.stringify(values)
      )

      commit('setPrintableValues', values)
    } else {
      const values = [
        ...JSON.parse(localStorage.getItem('akern_customer_printableValues')),
        value
      ]

      localStorage.setItem(
        'akern_customer_printableValues',
        JSON.stringify(values)
      )

      commit('setPrintableValues', values)
    }
  }
}
